import { ReactNode, useState, useEffect } from "react";
import { useAccount } from "wagmi";
import {
    AccountDto,
    AccountsData,
    AccountSummary,
    ApiConfigDto,
    DynamicPointsDisplayInfo,
    ReferralDto,
} from "@src/util/interfaces";
import { s0VaultABI } from "@src/contracts/s0VaultABI";
import TermsOfServiceDialog from "@Components/TermsOfService";
import { getPublicClient, getSelectedChainId } from "@src/viemConfig";
import { GlobalContext } from "./GlobalContext";
import { config } from "@src/config";
import { erc20Abi, formatUnits } from "viem";
import CustomToaster from "@Components/GeneralComponents/CustomToaster";
import { api, isRateLimited, isGeoBlocked } from "../../util/api";
import axios from "axios";
import { toReadableNumber } from "@src/util/tokenUtil";

interface GlobalProviderProps {
    children: ReactNode;
}
const dynamicPointsDisplayInfoDefault = { myPts: 0, totalRefereePts: 0, lastUpdated: 0 };

export function GlobalProvider({ children }: GlobalProviderProps) {
    // state vars
    const [accounts, setAccounts] = useState<AccountDto[] | null>(null);
    const [refereeAccounts, setRefereeAccounts] = useState<AccountSummary[] | null>(null);
    const [referral, setReferral] = useState<ReferralDto | null>(null);
    const [balance, setBalance] = useState<string>("");
    const [eUsdebalance, seteUsdeBalance] = useState<string>("");
    const [ytPendleBalance, setYtPendleBalance] = useState<string>("");
    const [lpPendleBalance, setLpPendleBalance] = useState<string>("");
    const [apiConfig, setApiConfig] = useState<ApiConfigDto>({
        contracts: {
            vaultMultiplier: 1,
            pendleYtMultiplier: 1,
            pendleLpMultiplier: 1,
            morphoApostroMultiplier: 1,
            eulerMultiplier: 1,
            morphoSparkMultiplier: 1,
        },
        referralPointPct: 10,
        pagination: { referees: 5, depositWithdrawals: 5 },
    });

    // This state variable is used to simulate points ticking up. It will be reset often
    const [dynamicPointsDisplayInfo, setDynamicPointsDisplayInfo] =
        useState<DynamicPointsDisplayInfo>(dynamicPointsDisplayInfoDefault);
    const [geoBlocked, setGeoBlocked] = useState<boolean>(false);

    const [withdrawalEnabled, setWithdrawalEnabled] = useState<boolean>(false);
    const [leaderboardRank, setLeaderboardRank] = useState<string | null>(null);
    const [currentFetchController, setCurrentFetchController] = useState<AbortController | null>(
        null
    );

    // hooks
    const { address } = useAccount();

    const publicClient = getPublicClient();
    const selectedChainId = getSelectedChainId();

    const VAULT_ADDRESS = config.contracts.vault.address;
    const VAULT_ASSET_TOKEN_ADDRESS = config.contracts.vault.assetToken.address;

    ////////// USE EFFECTS //////////
    // Fetch api config and clean up abort controller on unmount
    useEffect(() => {
        fetchApiConfig();
        return () => {
            if (currentFetchController) {
                currentFetchController.abort();
            }
        };
    }, []);

    useEffect(() => {
        if (!address) {
            // Clear state on disconnect
            setAccounts(null);
            setRefereeAccounts(null);
            setReferral(null);
            seteUsdeBalance("");
            setBalance("");
            setLeaderboardRank(null);
            setDynamicPointsDisplayInfo(dynamicPointsDisplayInfoDefault);
            setYtPendleBalance("");
            setLpPendleBalance("");

            return;
        }
        getBalance();
        geteUsdeBalance();
        fetchAccountData();
        getYtPendleBalance();
    }, [address]);

    useEffect(() => {
        checkWithdrawEnabled();
    }, [VAULT_ADDRESS]);

    useEffect(() => {
        if (!accounts || accounts.length === 0) {
            setDynamicPointsDisplayInfo(dynamicPointsDisplayInfoDefault);
            return;
        }

        // whenever we get an account update from the server, set the total points
        const totalPointsFromAccounts =
            accounts?.reduce((total, account) => {
                return total + Number(account.points);
            }, 0) || 0;
        const totalRefereePts =
            refereeAccounts?.reduce((total, account) => {
                return total + Number(account.points);
            }, 0) || 0;
        const now = new Date().getTime();
        setDynamicPointsDisplayInfo({
            myPts: totalPointsFromAccounts,
            totalRefereePts,
            lastUpdated: now,
        });
    }, [accounts, refereeAccounts]);

    ////////// END USE EFFECTS //////////

    async function fetchAccountData() {
        if (!address) return;
        // Cancel any in-flight request
        if (currentFetchController) {
            currentFetchController.abort();
        }
        // Create new controller for this request
        const controller = new AbortController();
        setCurrentFetchController(controller);

        try {
            const res = await api.get(`/account/${address}`, { signal: controller.signal });
            const accountsData: AccountsData = res.data;
            // format all points to readable numbers
            const accountsReadable: AccountDto[] = accountsData.accounts.map((account) => {
                return { ...account, points: toReadableNumber(account.points).toString() };
            });
            const refereeAccountsReadable: AccountSummary[] = accountsData.refereeAccounts.map(
                (referee) => {
                    return { ...referee, points: toReadableNumber(referee.points).toString() };
                }
            );
            setAccounts(accountsReadable);
            setRefereeAccounts(refereeAccountsReadable);
            setLeaderboardRank(accountsData.rank);
            // parse the pendle LP balance from the backend. We don't fetch it directly from the contract because
            // the balance is not a simple balanceOf call, but a more complex calculation
            const lpPendleBalance = accountsReadable.find(
                (account) => account.assetAddress === config.contracts.pendle.lpAddress
            )?.balance;
            if (lpPendleBalance) {
                setLpPendleBalance(formatUnits(BigInt(lpPendleBalance), 18));
            }
        } catch (error) {
            if (isGeoBlocked(error)) {
                setGeoBlocked(true);
            }
            // Don't update state if request was cancelled
            if (axios.isCancel(error)) {
                return;
            }

            console.error("Error fetching accounts data:", error);
            // don't reset accounts if rate limited
            if (isRateLimited(error)) {
                return;
            }

            setAccounts(null);
            setRefereeAccounts(null);
        } finally {
            if (currentFetchController === controller) {
                setCurrentFetchController(null);
            }
        }
    }

    const getYtPendleBalance = async () => {
        if (!address) return;
        const ytPendleBalance = await publicClient.readContract({
            address: config.contracts.pendle.ytAddress,
            abi: erc20Abi,
            functionName: "balanceOf",
            args: [address],
        });
        const ytPendleBalanceFormatted = formatUnits(ytPendleBalance || 0n, 18);
        setYtPendleBalance(ytPendleBalanceFormatted);
    };

    const geteUsdeBalance = async () => {
        if (!address) return;
        const tokenBalance = await publicClient.readContract({
            address: VAULT_ADDRESS,
            abi: s0VaultABI,
            functionName: "balanceOf",
            args: [address],
        });

        const balanceFormatted = formatUnits(
            tokenBalance || 0n,
            config.contracts.vault.assetToken.decimals
        );
        seteUsdeBalance(balanceFormatted);
    };

    const getBalance = async () => {
        if (!address) return;
        const tokenBalance = await publicClient.readContract({
            address: VAULT_ASSET_TOKEN_ADDRESS,
            abi: s0VaultABI,
            functionName: "balanceOf",
            args: [address],
        });

        const balanceFormatted = formatUnits(
            tokenBalance || 0n,
            config.contracts.vault.assetToken.decimals
        );
        setBalance(balanceFormatted);
    };

    const checkWithdrawEnabled = async () => {
        const withdrawEnabled = await publicClient.readContract({
            address: VAULT_ADDRESS,
            abi: s0VaultABI,
            functionName: "withdrawalsEnabled",
            args: [],
        });
        setWithdrawalEnabled(withdrawEnabled);
    };

    const fetchApiConfig = async () => {
        const resp = await api.get("/config");
        if (resp.status === 200) {
            const config: ApiConfigDto = resp.data;
            const mockedPendleLpConfig = {
                ...config,
                contracts: {
                    ...config.contracts,
                    // TODO, remove this once backend returns correct values.
                    pendleLpMultiplier: config.contracts.pendleYtMultiplier,
                },
            };
            setApiConfig(mockedPendleLpConfig);
        }
    };

    return (
        <GlobalContext.Provider
            value={{
                accounts,
                balance,
                getBalance,
                ytPendleBalance,
                lpPendleBalance,
                eUsdebalance,
                geteUsdeBalance,
                withdrawalEnabled,
                leaderboardRank,
                selectedChainId,
                referral,
                refetchAccountData: fetchAccountData,
                setReferral,
                dynamicPointsDisplayInfo,
                apiConfig,
            }}
        >
            <CustomToaster />
            <TermsOfServiceDialog geoBlocked={geoBlocked} setReferral={setReferral} />
            {children}
        </GlobalContext.Provider>
    );
}
