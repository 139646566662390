import * as React from "react";
import type { Placement } from "@floating-ui/react";
import { useTooltip } from "./useTooltip";
import { TooltipContext } from "./TooltipContext";

interface TooltipOptions {
    initialOpen?: boolean;
    placement?: Placement;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}

export function TooltipProvider({
    children,
    ...options
}: { children: React.ReactNode } & TooltipOptions) {
    const tooltip = useTooltip(options);
    return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}
