import { Outlet, useLocation } from "react-router-dom";
import TopNavbar from "./TopNavbar";
import Footer from "./Footer";
import { GlobalProvider } from "@src/hooks/Global/GlobalProvider";
import ErrorPage from "../pages/Error/ErrorPage";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import FadeInImage from "./GeneralComponents/FadeInImage";

function Layout() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <GlobalProvider>
            <div className="min-h-screen flex flex-col overflow-hidden relative">
                <TopNavbar />
                <div
                    className="absolute shrink-0 pointer-events-none left-[500px] md:left-2/3 lg:left-1/2 top-0 -translate-x-1/2 -z-10"
                    style={{
                        width: 1000,
                        height: 900,
                        transform: "translate(-130%, -128px)",
                    }}
                >
                    <FadeInImage
                        draggable="false"
                        src="/ring-nobg-cropped-whitespace.png"
                        opacityClassName="opacity-50"
                        style={{
                            width: 1000,
                            height: 900,
                        }}
                    />
                </div>
                <div
                    className="absolute shrink-0 pointer-events-none left-1/2 bottom-0 -translate-x-1/2 -z-10"
                    style={{
                        width: 950,
                        height: 1017,
                        transform: "translate(3%, 262px)",
                    }}
                >
                    <FadeInImage
                        style={{ width: 950, height: 1017 }}
                        opacityClassName="opacity-50"
                        draggable="false"
                        src="/blob-nobg-cropped-whitespace.png"
                    />
                </div>
                <main className="pb-32 grow flex justify-center items-center relative">
                    <div className="mt-8 max-w-5xl w-full">
                        <Outlet />
                    </div>
                </main>
                <Footer />
            </div>
        </GlobalProvider>
    );
}
const WrappedLayout = Sentry.withErrorBoundary(Layout, {
    fallback: <ErrorPage />,
});
export default WrappedLayout;
