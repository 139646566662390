import { Button } from "@Catalyst/button";
import {
    ArrowLongLeftIcon,
    ArrowLongRightIcon,
    ArrowPathIcon,
    ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/solid";
import { config } from "@src/config";
import {
    formatNumberTwoDecimalsFromString,
    formatTimestamp,
    getEtherscanLink,
} from "@src/util/util";
import { api } from "../../../util/api";
import { useEffect, useState } from "react";
import { formatUnits } from "viem";
import { useAccount } from "wagmi";
import {
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@Components/GeneralComponents/Tooltip";
import { formatDistance } from "date-fns";
import { twMerge } from "tailwind-merge";
import { BREAKPOINTS, useScreenSize } from "@src/hooks/useScreenSize";
import { useGlobalContext } from "@src/hooks/Global/useGlobalContext";

interface Deposit {
    type: string;
    amount: string;
    date: number;
    txnHash: string;
}

interface PageCache {
    [pageNumber: number]: Deposit[];
}

function DepositHistory() {
    const { address } = useAccount();
    const { width } = useScreenSize();
    const [currPageNumber, setCurrPageNumber] = useState(1);
    const [pageCache, setPageCache] = useState<PageCache>({});
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    const { apiConfig } = useGlobalContext();
    const PAGE_SIZE = apiConfig.pagination.depositWithdrawals; // single page size value used for both UI and API
    const canGoNext = currPageNumber * PAGE_SIZE < totalCount;
    const currentDeposits = pageCache[currPageNumber] || [];

    useEffect(() => {
        // Reset cache and fetch first page when address changes
        setPageCache({});
        fetchHistory(1);
    }, [address]);

    const handlePageChange = async (newPage: number) => {
        // Only fetch if we don't have this page cached
        if (!pageCache[newPage]) {
            await fetchHistory(newPage);
        }
        setCurrPageNumber(newPage);
    };

    const fetchHistory = async (page: number) => {
        if (!address || isLoading) return;

        setIsLoading(true);
        try {
            const res = await api.get(`/account/${address}/transfer-history`, {
                params: { address, page },
            });
            const data: { history: Deposit[]; transferCount: number } = res.data;
            setTotalCount(data.transferCount);
            setPageCache((prev) => ({ ...prev, [page]: data.history }));
        } catch (error) {
            console.error("Error fetching transfer history", error);
        } finally {
            setIsLoading(false);
        }
    };

    const getDepositTypeDisplay = (type: string) => {
        switch (type) {
            case "deposit":
                return "Deposit";
            case "withdraw":
                return "Withdraw";
            default:
                return type;
        }
    };

    const renderRows = () => {
        if (isLoading)
            return (
                <div className="flex flex-row justify-center items-center">
                    <ArrowPathIcon className="!w-5 !h-5 animate-spin fill-text-secondary" />
                </div>
            );
        if (totalCount === 0)
            return (
                <div className="text-text-secondary text-xs py-2">
                    Your deposits and withdrawals will appear here
                </div>
            );

        return currentDeposits.map((deposit, i) => {
            const fullAmount = formatUnits(
                BigInt(deposit.amount),
                config.contracts.vault.assetToken.decimals
            );
            const link = getEtherscanLink(deposit.txnHash);

            return (
                <div
                    className="flex flex-row justify-between border-b border-border py-2 items-center"
                    key={`${i}_${deposit.date}`}
                >
                    <div>
                        {link ? (
                            <a
                                className="text-xs flex items-center gap-2"
                                target="_blank"
                                href={link}
                                rel="noopener noreferrer"
                            >
                                {getDepositTypeDisplay(deposit.type)}
                                <ArrowTopRightOnSquareIcon className="size-3" />
                            </a>
                        ) : (
                            <div className="text-xs">{getDepositTypeDisplay(deposit.type)}</div>
                        )}
                        <div className="text-text-secondary text-[10px]">
                            <TooltipProvider>
                                <TooltipTrigger>{formatTimestamp(deposit.date)}</TooltipTrigger>
                                <TooltipContent>
                                    <span className="text-xs">
                                        {formatDistance(new Date(deposit.date), new Date(), {
                                            addSuffix: true,
                                        })}
                                    </span>
                                </TooltipContent>
                            </TooltipProvider>
                        </div>
                    </div>
                    <div className="text-xs">
                        <TooltipProvider placement={width <= BREAKPOINTS.lg ? "left" : "top"}>
                            <TooltipTrigger>
                                {formatNumberTwoDecimalsFromString(fullAmount)} USDe
                            </TooltipTrigger>
                            <TooltipContent>
                                <span className="text-xs">{fullAmount} USDe</span>
                            </TooltipContent>
                        </TooltipProvider>
                    </div>
                </div>
            );
        });
    };
    return (
        <div className="px-4 flex flex-col justify-between h-full flex-1">
            <div className="min-h-[240px]">{renderRows()}</div>
            <div
                className={twMerge("flex flex-row justify-end mb-0", totalCount === 0 && "hidden")}
            >
                <Button
                    data-testid="deposit-history-previous-btn"
                    plain
                    disabled={currPageNumber == 1}
                    onClick={() => handlePageChange(currPageNumber - 1)}
                >
                    <ArrowLongLeftIcon className="!size-4 !mr-1 fill-text-primary" />{" "}
                    {currPageNumber == 1 ? "" : "Previous"}
                </Button>
                <Button
                    data-testid="deposit-history-next-btn"
                    plain
                    disabled={!canGoNext}
                    onClick={() => handlePageChange(currPageNumber + 1)}
                >
                    {canGoNext ? "Next" : ""}
                    <ArrowLongRightIcon className="!size-4 !ml-1 fill-text-primary" />
                </Button>
            </div>
        </div>
    );
}

export default DepositHistory;
