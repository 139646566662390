import { Button } from "@Catalyst/button";
import { Dialog, DialogBody } from "@Catalyst/dialog";
import { ArrowLongLeftIcon, ArrowLongRightIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { config } from "@src/config";
import { Referee } from "@src/util/interfaces";
import { formatNumberTwoDecimalsFromString, formatTimestamp, shortenAddress } from "@src/util/util";
import { api } from "../../../util/api";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { Divider } from "@Catalyst/divider";
import {
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@Components/GeneralComponents/Tooltip";
import { formatDistance } from "date-fns";
import { toReadableNumber } from "@src/util/tokenUtil";
import { formatUnits } from "viem";
import { useGlobalContext } from "@src/hooks/Global/useGlobalContext";

interface Props {
    open: boolean;
    onClose: () => void;
}

interface PageCache {
    [pageNumber: number]: Referee[];
}

const RefereesDialog: React.FC<Props> = ({ open, onClose }) => {
    const [currPageNumber, setCurrPageNumber] = useState(1);
    const [pageCache, setPageCache] = useState<PageCache>({});
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    const { address } = useAccount();
    const { apiConfig } = useGlobalContext();
    const apiUrl = config.services.apiUrl;

    const PAGE_SIZE = apiConfig.pagination.referees; // single page size value used for both UI and API
    const referees: Referee[] = pageCache[currPageNumber] || [];
    const canGoNext = currPageNumber * PAGE_SIZE < totalCount;
    const multiplePages = totalCount > PAGE_SIZE;
    const rowContainerClassName = multiplePages ? "md:min-h-[245px]" : "";

    // Reset cache when address changes
    useEffect(() => {
        setPageCache({});
    }, [address]);

    // Fetch referees page 1 when dialog is opened
    useEffect(() => {
        if (open) {
            fetchReferees(1);
        }
        setCurrPageNumber(1);
    }, [open]);

    const handlePageChange = async (newPage: number) => {
        // Only fetch if we don't have this page cached
        if (!pageCache[newPage]) {
            await fetchReferees(newPage);
        }
        setCurrPageNumber(newPage);
    };

    const fetchReferees = async (page: number) => {
        if (!address || isLoading) return;
        setIsLoading(true);
        try {
            const url = `${apiUrl}/account/${address}/referees`;
            const res = await api.get(url, { params: { address, page } });

            const data: { referees: Referee[]; referralCount: number } = res.data;
            // Format referee total rewards to a readable number
            const refereesFormatted = data.referees.map((referee: Referee) => {
                return {
                    ...referee,
                    totalRewards: toReadableNumber(referee.totalRewards).toString(),
                };
            });
            setTotalCount(data.referralCount);
            setPageCache((prev) => ({ ...prev, [page]: refereesFormatted }));
        } catch (error) {
            console.error(`Error fetching referees: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    const headers = [
        { name: "Referee's address", className: "w-1/4 hidden md:block" },
        { name: "Balance", className: "w-1/3 md:w-1/6 md:text-right" },
        { name: "Points", className: "w-1/3 md:w-1/6 text-right" },
        { name: "Date referred", className: "w-1/3 md:w-1/4 text-right" },
    ] as const;

    const renderHeader = ({ name, className }: { name: string; className?: string }) => {
        const headerClassName = `${className || ""} text-[10px] font-medium text-text-secondary`;

        return (
            <div key={name} className={headerClassName}>
                {name}
            </div>
        );
    };

    const renderRow = (referee: Referee) => {
        const rowClassName = `md:text-xs text-[10px]`;
        return (
            <div key={referee.refereeAddress}>
                {/*Show on Mobile only */}
                <div className="block md:hidden p-4 text-xs">
                    {shortenAddress(referee.refereeAddress)}
                </div>
                <div className="flex md:hidden items-center justify-between py-2 px-4 border-b border-border bg-backgroundSecondary rounded">
                    {headers.map((h) => renderHeader(h))}
                </div>

                <div
                    className="flex flex-row justify-between border-b border-border p-4 items-center"
                    key={referee.refereeAddress}
                >
                    <div className={`${headers[0].className} text-xs`}>
                        {shortenAddress(referee.refereeAddress)}
                    </div>
                    <div className={`${headers[1].className} text-xs`}>
                        {formatNumberTwoDecimalsFromString(
                            formatUnits(
                                BigInt(referee.eUsdeAmount),
                                config.contracts.vault.assetToken.decimals
                            )
                        )}
                    </div>
                    <div className={`${headers[2].className} ${rowClassName}`}>
                        {formatNumberTwoDecimalsFromString(referee.totalRewards)}{" "}
                    </div>
                    <div className={`${headers[3].className} ${rowClassName}`}>
                        <TooltipProvider>
                            <TooltipTrigger className="text-right">
                                {formatTimestamp(referee.date)}
                            </TooltipTrigger>
                            <TooltipContent>
                                {formatDistance(new Date(referee.date), new Date(), {
                                    addSuffix: true,
                                })}
                            </TooltipContent>
                        </TooltipProvider>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className={`!p-0 fixed overflow-x-hidden overflow-y-auto flex flex-col !bg-backgroundPrimary 
                top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] max-w-[90%] md:max-w-4xl mx-auto !rounded-md max-h-[90vh]`}
        >
            <div className="flex flex-row justify-between items-center p-4 text-base">
                Referees
                <button data-testid="referee-close-btn" onClick={onClose}>
                    <XMarkIcon className="!size-6 fill-text-secondary hover:brightness-150" />
                </button>
            </div>
            <Divider />
            <DialogBody className="p-4 pt-0 !mt-0 md:!mt-4 ">
                {/* Header */}
                <div className="hidden md:flex py-1 px-4 items-center justify-between bg-backgroundSecondary">
                    {headers.map((h) => renderHeader(h))}
                </div>
                {totalCount === 0 && (
                    <div className="p-4 text-xs text-text-secondary">
                        Your referees will show up here
                    </div>
                )}
                <div className={rowContainerClassName}>{referees.map(renderRow)}</div>
                {referees.length > 0 && (
                    <div className="flex flex-row justify-center mt-3 mb-0">
                        <Button
                            data-testid="referee-previous-btn"
                            plain
                            disabled={currPageNumber == 1}
                            className="text-xs"
                            onClick={() => handlePageChange(currPageNumber - 1)}
                        >
                            <ArrowLongLeftIcon className="!size-4 !mr-1 fill-text-primary" />{" "}
                            {currPageNumber == 1 ? "" : "Previous"}
                        </Button>
                        <Button
                            data-testid="referee-next-btn"
                            plain
                            disabled={!canGoNext}
                            className="text-xs"
                            onClick={() => handlePageChange(currPageNumber + 1)}
                        >
                            {canGoNext ? "Next" : ""}
                            <ArrowLongRightIcon className="!size-4 !ml-1 fill-text-primary" />
                        </Button>
                    </div>
                )}
            </DialogBody>
        </Dialog>
    );
};
export default RefereesDialog;
