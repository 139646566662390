import { PRIVACY_ROUTE } from "@src/routes";
import { ReactNode } from "react";

interface Section {
    title: string;
    description?: string | ReactNode;
    subSections?: Section[];
}
function TermsPage() {
    const LAST_UPDATED = "February 7, 2025";
    const liClassName = "mt-4";
    const liTitleClassName = "font-semibold underline";

    const terms: Section[] = [
        {
            title: "ELIGIBILITY ",
            subSections: [
                {
                    title: "Generally",
                    description: `If you are an individual accessing or using the Services, you represent and warrant that you: (a) are at least 18 years old; (b) are capable of forming a binding contract with us in the jurisdiction you reside in; (c) have the full right, power, and authority to agree to these Terms; (d) are not a Restricted User (defined below); and (e) are using the Services solely for your own benefit and not on behalf of, or for the benefit of, any third party. If you access or use the Services on behalf of a legal entity or organization, you also represent and warrant that you are authorized to agree to these Terms on behalf of that legal entity or organization and have the power and authority to bind the legal entity or organization to these Terms. `,
                },
                {
                    title: "Restricted Users",
                    description: (
                        <div>
                            You represent and warrant you are not: (a) located in or under the
                            control of any individual, organization, or group located in, the United
                            States; (b) located in, under the control of, or a resident of any
                            jurisdiction that is comprehensively sanctioned or embargoed by the
                            United States, the United Nations, or the United Kingdom; (c) a
                            resident, national, or agent of Cuba, certain sanctioned areas of Russia
                            and Ukraine (including without limitation, Crimea, the so-called region
                            of Donetsk, the so-called region of Luhansk, and the so-called region of
                            Zaporizhzhia), Democratic People’s Republic of Korea (North Korea),
                            Iran, and Syria; (d) a citizen, resident, located in, or organized in a
                            jurisdiction where your access or use of the Services would be illegal
                            or violate applicable law; or (e) subject to any export restriction,
                            end-user restriction, anti-terrorism law, anti-money laundering law,
                            economic sanction, financial sanction, or trade embargo imposed,
                            administered, or enforced by the United States Department of Treasury’s
                            Office of Foreign Asset Control, United States Department of State,
                            United States Department of Commerce, United Nations Security Council,
                            or other applicable national, regional, provincial, state, municipal, or
                            local law or regulation, each, as amended (a “
                            <span className="underline">Restricted User</span>”).
                            <span className="block mt-2">
                                We reserve the right to enforce geo-blocking of the Services. You
                                are prohibited from using any software or networking techniques
                                including a Virtual Private Network (“
                                <span className="underline">VPN</span>") to modify your internet
                                protocol address or otherwise circumvent or attempt to circumvent
                                this prohibition. You acknowledge and agree that you will not hold
                                us responsible for, and will indemnify us from, any contravention
                                that may result from the access of the Services through a VPN or any
                                similar means.
                            </span>
                        </div>
                    ),
                },
            ],
        },
        {
            title: "DIGITAL ASSET WALLETS",
            description: (
                <div>
                    To use certain functions of the Services, you must connect a compatible software
                    application (or other mechanism) (“
                    <span className="underline">Digital Asset Wallet</span>”). Your relationship
                    with the provider of any Digital Asset Wallet you use in connection with the
                    Services is governed by the terms and conditions of that provider’s agreement
                    with you. By connecting a Digital Asset Wallet to the Platform, you agree to be
                    bound by this Agreement. We reserve the right, in our sole discretion, to
                    prohibit certain Digital Asset Wallet’s from connecting to the Platform or from
                    interacting with any part of the Services.
                </div>
            ),
            subSections: [
                {
                    title: "Non-Custodial",
                    description: (
                        <div>
                            The Services are non-custodial applications; we do not, at any time,
                            custody, possess, or control the virtual currency, cryptocurrency,
                            stablecoins, or other cryptographic tokens (collectively, “
                            <span className="underline">Digital Assets</span>”) in your Digital
                            Asset Wallet. As the owner of the Digital Assets stored by the Digital
                            Asset Wallet, you acknowledge and agree that you bear all risk of loss
                            regarding such Digital Assets and you will not hold us liable for
                            Digital Asset fluctuations or other loss associated with any Digital
                            Asset Wallet you use in connection with the Services.
                        </div>
                    ),
                },
                {
                    title: "Security",
                    description: `You are solely responsible for the custody of the cryptographic private keys associated with any Digital Asset Wallet you use or connect to the Services. You should never share your Digital Asset Wallet credentials or seed phrase with anyone. We accept no responsibility for, or liability to you, in connection with your use of a Digital Asset Wallet. We make no representations or warranties regarding how any of the Services will interact or operate with any specific Digital Asset Wallet. `,
                },
            ],
        },
        {
            title: "THE PLATFORM",
            description: (
                <div>
                    <p>
                        The Platform provides a web or mobile-based means to access software
                        protocols that run on blockchain networks (collectively, the “
                        <span className="underline">Protocol</span>”). The Protocol enables users to
                        perform transactions with Digital Assets compatible with the underlying
                        blockchain network. The Platform is distinct from the Protocol. The Platform
                        is one but not the exclusive means of accessing the Protocol. The Protocol
                        itself is comprised of open-source or source-available self-executing smart
                        contracts.{" "}
                    </p>
                    <p className="mt-2">
                        You acknowledge and agree that we do not control the Protocol and do not
                        have control over Digital Asset transactions conducted on the Protocol. You
                        also acknowledge and agree that your interactions or transactions including,
                        without limitation, Digital Asset transactions, are not transactions with
                        us. You further understand and agree that we do not own, operate, or control
                        the Protocol’s liquidity pools and do not control trade execution on the
                        Protocol.
                    </p>
                </div>
            ),
        },
        {
            title: "THIRD PARTY SERVICES AND WAIVER",
            description: (
                <div>
                    The Services may include, without limitation, links to sites, technology,
                    applications, products, services, materials, or resources, provided or made
                    available by a third party including, without limitation, the Protocol
                    (collectively, “<span className="underline">Third Party Services</span>”). Your
                    access and/or use of a Third Party Service is subject to the terms and policies
                    of the applicable provider of the Third Party Service. We do not control any
                    Third Party Service.{" "}
                </div>
            ),
            subSections: [
                {
                    title: "Third Party Services",
                    description: (
                        <div>
                            <p>
                                You acknowledge and agree that you are solely responsible for all
                                costs and charges associated with your use of a Third Party Service.
                                Our integration or inclusion of a Third Party Service does not imply
                                endorsement or recommendation. You also acknowledge and agree that
                                we are not responsible for the availability, reliability, accuracy,
                                or legitimacy of any Third Party Service (including any related
                                websites, resources or links displayed therein). Any dispute you
                                have with a Third Party Service provider including, without
                                limitation, your intellectual property rights, is between you and
                                the applicable provider of the Third Party Service. We will not be
                                responsible or liable for any damage or loss caused or alleged to be
                                caused by, or in connection with, your use of, or reliance on, a
                                Third Party Service.
                            </p>
                            <p>
                                If, to the extent we permit, you permit a third party or Third Party
                                Service to access or connect to the Services, you acknowledge and
                                agree that granting permission to any third party to take specific
                                actions on your behalf does not relieve you of any of your
                                responsibilities under these Terms. You are fully responsible for
                                any act or omission of any third party. You acknowledge and agree
                                that you will not hold us responsible for, and will indemnify us
                                from, any and all liability arising out of or related to any third
                                party’s act or omission with access to your Digital Asset Wallet,
                                application, software, or other mechanism that you use to interact
                                with the Services.
                            </p>
                        </div>
                    ),
                },
                {
                    title: "Waiver of Claims",
                    description: `To the maximum extent permitted by applicable law, you waive any and all claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, against us and our affiliates, and each of our respective officers, employees, agents, and successors arising out of or in any way related to any of the risks set forth herein. You also waive application of Section 1542 of the Civil Code of the State of California, or any similar stature or law of any other jurisdiction. Section 1542 reads as follows: “a general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which if known by him or her must have materially affected his or her settlement with the debtor.” `,
                },
            ],
        },
        {
            title: " REWARDS",
            description: (
                <div>
                    We may provide certain incentives, prizes, or rewards for completing certain
                    activities (“<span className="underline">Rewards</span>”). We reserve the right
                    to change, modify, discontinue, or cancel any Reward at any time and without
                    notice to you. You understand and agree that we do not guarantee that you will
                    receive a Reward or other incentive. Any information regarding the potential
                    amount of a Reword is an estimate only. We expressly disclaim all
                    representations and warranties regarding any Reward including, without
                    limitation: (a) any stated percentage of a Reward that will be received; (b) any
                    Reward will occur on a continuous basis; (c) that Rewards will be on an
                    uninterrupted basis or error-free basis; and/or (d) any particular Digital Asset
                    will be available in connection with the Reward. Any Reward we make available
                    from time to time is subject to these Terms and any other applicable term or
                    provision regarding such Reward. Some or all of your Rewards may be
                    programmatically destroyed as a penalty imposed by the applicable underlying
                    blockchain network (“<span className="underline">Slashing Penalty</span>”).
                    Ethereal will not be responsible or liable for any loss or penalty of a Reward
                    or Slashing Penalty.
                </div>
            ),
        },
        {
            title: "RISK DISCLOSURES",
            description: (
                <div>
                    You understand, accept, and agree to assume all of the various risks involved in
                    using the Services and holding, transacting, and transferring Digital Assets
                    including all of the risks set forth below.
                    <ul className="list-disc pl-10">
                        <li className={liClassName}>
                            Digital Assets, the features, functions, characteristics, operations,
                            use, and other properties and/or software, networks, protocols, systems,
                            or other technology that Digital Assets interact with are complex; and
                            the terms, features, or risks described herein are not a complete or
                            exhaustive list of all such features, risks, or complexities and may not
                            be readily or fully understood due to such complexities.
                        </li>
                        <li className={liClassName}>
                            If you act as a liquidity provider through the Services, you understand
                            that your Digital Asset may lose some or all value while supplied to the
                            Protocol due to fluctuations in Digital Asset prices in a trading pair
                            or liquidity pool.
                        </li>
                        <li className={liClassName}>
                            Staking rewards are not guaranteed and may be based on network
                            performance, validator reliability, and other external factors. Ethereal
                            does not assure any specific level of returns or profitability from
                            staking activities.
                        </li>
                        <li className={liClassName}>
                            If you supply Digital Asset to a liquidity pool in an automated market
                            maker (“<span className="underline">AMM</span>”), your deposited assets
                            may lose relative value as the ratio of the Digital Assets in the
                            liquidity pool fluctuate (“
                            <span className="underline">Impermanent Loss</span>”). An AMM functions
                            by allowing market forces to rebalance the ratio of Digital Assets in a
                            liquidity pool. Once this rebalancing or Digital Assets occurs, you may
                            end up withdrawing a different ratio of Digital Assets than when you
                            deposited your Digital Assets into the liquidity pool. As such, you may
                            perceive a “loss” in value in U.S. dollar terms due to the rebalancing
                            of the Digital Assets in the liquidity pool. You represent that you
                            understand the risk of Impermanent Loss. You further represent that you
                            have carefully considered the likelihood of price divergence in a
                            liquidity pool, and that you understand that we cannot control the
                            amount of loss you incur.
                        </li>
                        <li className={liClassName}>
                            You understand that we do not create, own, or operate cross-chain
                            bridges and we do not make any representation or warranty about the
                            safety or soundness of any cross-chain bridge.
                        </li>
                        <li className={liClassName}>
                            Digital Assets will be irretrievably lost if sent to the wrong address.
                            For instance, if the address is improperly formatted, contains an error,
                            or for a different type of Digital Asset.
                        </li>
                        <li className={liClassName}>
                            Blockchain networks and Digital Assets may be subject to forks or
                            attacks on the security, integrity, and/or operation of the networks,
                            including any network events. These events may affect features,
                            functionality, operations, use, or properties of any Digital Asset or
                            network and/or the value of any Digital Asset.
                        </li>
                        <li className={liClassName}>
                            Collateral for decentralized finance protocols and projects may be
                            higher than similar centralized protocols and projects.
                        </li>
                        <li className={liClassName}>
                            Any Digital Asset and/or the Services may be targeted by malicious
                            persons or individuals who may attempt to disrupt the Services or steal
                            Digital Assets. This includes but is not limited to malware, hacking,
                            phishing, double spending, smurfing, spoofing, sybil attacks, social
                            engineering, majority mining, mining attacks, distributed denial of
                            service, and blockchain forks.
                        </li>
                        <li className={liClassName}>
                            The public nature of the Internet means that parts or the Internet may
                            be unreliable or unavailable at any given time. Interruption, delay,
                            corruption, loss of data, the loss of confidentiality or privacy through
                            the course of data transmission, or malware transmission may occur when
                            transmitting data via the Internet or other technology. This can result
                            in your transaction(s) not being executed according to your
                            instructions, at the requested time, and/or not at all. No technology is
                            completely secure or safe.
                        </li>
                        <li className={liClassName}>
                            Digital Assets may decrease in value or lose all value, in a short
                            period of time or permanently, due to various factors including, without
                            limitation, government or regulatory activity, the discovery of wrongful
                            or illegal conduct, market manipulation, price distortion, insider
                            dealing, market distortion, malicious wrongdoing or behaviors, changes
                            to the Digital Asset’s nature or characteristics, suspension, or
                            cessation of support for a Digital Asset by exchanges, public opinion,
                            technical advancements, macroeconomic and political factors, and other
                            factors outside of our control
                        </li>
                        <li className={liClassName}>
                            Digital Assets held by a decentralized application and Digital Asset
                            Wallets are not protected deposits and may not be protected by any
                            deposit protection scheme. Thus, Digital Assets have a reduced level and
                            type of protection compared to fiat and other asset classes or types.
                        </li>
                        <li className={liClassName}>
                            The creation of the Protocol may be abandoned for reasons including
                            without limitation, lack of interest, funding, incapacitation of key
                            developers or project members, lack of commercial success or prospects,
                            or Force Majeure Event (defined below). You understand that there are no
                            assurances regarding any benefit to interacting with the Services, even
                            if the project and/or the Protocol was partially or fully developed and
                            launched.
                        </li>
                        <li className={liClassName}>
                            The Services may undergo significant changes over time. We may also
                            limit control over how other visitors and/or users can use the Services
                            and what services are offered on or through the Platform. This could
                            create the risk of the Services not meeting your expectations, for any
                            number of reasons, including mistaken assumptions or analysis, a change
                            in the design and/or implementation plans, or execution on or through
                            the Services.
                        </li>
                        <li className={liClassName}>
                            We currently rely on our service providers for certain aspects of our
                            operations including cloud computing services and data centers that
                            provide facilities, infrastructure, website functionality and access,
                            components, and services, all of which are critical to our operations.
                            Like most other online companies, because we rely on service providers,
                            we face operational risk. Any interruption in the services provided by
                            our service providers can impair our ability to provide the Services.
                        </li>
                        <li className={liClassName}>
                            We do not directly manage the operation of the service providers we use
                            including their data center facilities. Such third parties are
                            vulnerable to financial, legal, regulatory, and labor issues,
                            cybersecurity incidents, break-ins, computer viruses, denial-of-service
                            attacks, sabotage, acts of vandalism, privacy breaches, service
                            terminations, disruptions, interruptions, Force Majeure Events, and
                            other events.
                        </li>
                        <li className={liClassName}>
                            Digital Asset transactions rely on smart contracts stored on various
                            blockchains, cryptographic tokens generated by smart contracts, and
                            other nascent software, applications, and systems that interact with
                            blockchain-based networks. Such technologies are experimental,
                            speculative, inherently risky, and subject to change. A defining feature
                            of blockchain technology is its entries are immutable, which means, as a
                            technical matter, they generally cannot be deleted or modified by
                            anyone. You acknowledge and understand smart contracts dictate how funds
                            and ownership of Digital Assets are distributed.
                        </li>
                        <li className={liClassName}>
                            You acknowledge and understand that you may be subject to scams and/or
                            other types of fraud perpetrated by parties outside of our control. It
                            is your responsibility to be aware of and protect against such
                            misconduct. In the event that you are subject to such fraud, there is a
                            risk of loss of your Digital Assets.
                        </li>
                        <li className={liClassName}>
                            All blockchain transactions include data and, in some circumstances,
                            personal data about you. Many blockchain technologies store transaction
                            data publicly and permanently. When you use such public blockchain
                            technology, you intentionally make your transaction data public and
                            acknowledge that this data cannot be deleted, removed, or reversed due
                            to the nature of blockchain technology.
                        </li>
                        <li className={liClassName}>
                            We are subject to an extensive and rapidly evolving regulatory
                            landscape, and any changes to any law or regulation could adversely
                            impact our ability to offer the Services and/or your use or access to
                            the Services. Such regulatory change may also impact your legal
                            obligations with respect to your use of the Services.
                        </li>
                        <li className={liClassName}>
                            You understand that smart contract transactions automatically execute
                            and settle, and blockchain-based transactions are irreversible when
                            confirmed. You acknowledge and accept that the cost and speed of
                            transacting with cryptographic and blockchain-based systems are variable
                            and may increase dramatically at any time. You further acknowledge and
                            accept the risk of selecting your slippage rate which expose you to
                            additional cost or fees by the underlying blockchain network.
                        </li>
                    </ul>
                </div>
            ),
        },
        {
            title: "ACKNOWLEDGEMENTS AND COVENANTS",
            description:
                "By accessing or using the Services, you acknowledge, agree, represent, and warrant, in each case as applicable, each of the items contained in this section.",
            subSections: [
                {
                    title: "Acknowledgement and Assumption of Risks",
                    description: `You represent and warrant that you have received a copy of, have carefully read, understand, accept, and agree to assume all of the risks involved with using, holding, trading, delivering, purchasing transacting, and/or transferring Digital Assets and access or use of the Services including without limitation, the risks specifically set forth in this Agreement. You agree that we will not be liable to you for any loss, damage, expense, or liability that is or may relate to any of the risks specifically set forth in this Agreement.  You represent and agree that you are able to bear any financial or other loss associated with or that may otherwise relate to your access or use of the Services. `,
                },
                {
                    title: "Non-Reliance",
                    description: (
                        <div>
                            <p>
                                You represent and warrant that you are not relying on (and will not
                                at any time rely on) our communication (written or oral) as advice
                                or as a recommendation to engage in any transaction or interaction
                                involving the Services. You agree that we have not: (a) given any
                                guarantee or representation as to the potential success, return,
                                effect, or benefit (either legal, regulatory, tax, financial,
                                accounting, or otherwise) of transacting in Digital Assets and/or
                                using the Services; and (b) made any representation to you regarding
                                the legality of transacting in Digital Assets or the Services under
                                applicable law. In deciding to use the Services, you are not relying
                                on advice or recommendations of ours, and you have made your own
                                independent decision that using the Services is suitable and
                                appropriate for you.
                            </p>
                            <p className="mt-2">
                                You acknowledge and agree that we do not provide investment advice
                                and any content on the Services or other communication channel
                                should not be considered as tailored investment advice. You must
                                seek professional advice regarding your particular financial, legal,
                                technical, and other conditions prior to commencing your use of the
                                Services. You represent and warrant that you fully understand all
                                risks associated with using the Services and you have the necessary
                                experience, understanding, and risk tolerance for using the Services
                                including the necessary experience and knowledge to enter into any
                                use or transaction regarding the Services. You acknowledge and
                                accept any and all risks regarding the Services and are responsible
                                for conducting your own independent analysis of the risks specific
                                to your access or use of the Services.
                            </p>
                        </div>
                    ),
                },
            ],
        },
        {
            title: "PROHIBITED USE",
            description: (
                <div>
                    You may not use the Services to engage in the following categories of activity
                    (each a “<span className="underline">Prohibited Use</span>”). The specific types
                    of activities listed below are representative, but not exhaustive.
                    <ul className="mt-2">
                        <li className={liClassName}>
                            <span className={liTitleClassName}>Unlawful Activity.</span> Activity
                            which, in any way, would violate, or assist in violation of, any law,
                            statue, ordinance, or regulation, sanctions programs administered in the
                            countries where Ethereal offers the Services, or which would involve
                            proceeds of any unlawful activity; publish, distribute, or disseminate
                            any unlawful material or information.
                        </li>
                        <li className={liClassName}>
                            <span className={liTitleClassName}> Abusive of Others. </span> Interfere
                            with another individual’s access to or use of the Services including but
                            not limited to exploiting, harming, or attempting to exploit or harm
                            minors in any way by exposing them to inappropriate content; defame,
                            abuse, extort, harass, stalk, threaten, or otherwise violate or infringe
                            the legal rights of others; ask for personally identifiable information,
                            or otherwise; transmit, or procure the sending of, any advertising or
                            promotional material, including any “junk mail,” “chain letter,” “spam,”
                            or any other similar solicitation; to impersonate or attempt to
                            impersonate Ethereal, an employee, another user, or any other person or
                            entity (including, without limitation, by using email addresses, screen
                            names, similarly named or commonly misspelled URLs, or associated
                            blockchain identities); engage in any other conduct that restricts or
                            inhibits anyone's use or enjoyment of the Services; incite, threaten,
                            encourage, or promote hate, racial intolerance, or violent acts against
                            others.
                        </li>
                        <li className={liClassName}>
                            <span className={liTitleClassName}>Fraud.</span> Activity which operates
                            to deceive or defraud, or attempt to deceive or defraud, Ethereal, any
                            users or any other person, including without limitation providing any
                            false, inaccurate, or misleading information whether directly through
                            the Services or through an external means that affects the Services with
                            the intent to unlawfully obtain the property of another or to provide
                            knowingly or recklessly false information, including in any way that
                            causes inaccuracy among the content on the Services.
                        </li>
                        <li className={liClassName}>
                            <span className={liTitleClassName}>Gambling.</span> Lotteries; bidding
                            fee auctions; sports forecasting or odds making; fantasy sports leagues
                            with cash prizes; internet gaming; contests; sweepstakes; games of
                            chance.
                        </li>
                        <li className={liClassName}>
                            <span className={liTitleClassName}>Abusive Activity.</span> To cause the
                            Services, the Platform underlying blockchain network(s) or technologies,
                            or any other functionality with which the Services interact, to work
                            other than as intended; damage the reputation of Ethereal, or impair any
                            of our legal rights or interests; engage in any activity or behavior
                            that violates any applicable laws concerning, or otherwise damages, the
                            integrity of the Services or any other service or software which relies
                            on the Services; use the Services in any manner that could disable,
                            overburden, damage, impair, or interfere with the Services, including
                            the ability to engage in real time activities through the Services; use
                            any robot, spider, or other automatic device, process, or means to
                            access the Services for any purpose, including monitoring or copying any
                            of the material on the Services; attempt to gain unauthorized access to,
                            interfere with, damage, or disrupt any parts of the Services, the server
                            on which the Services or information in connection with the Services is
                            stored, or any server, computer, or database connected to the Services,
                            including any underlying blockchain; through the use of a VPN software
                            or any other privacy or anonymization tools or techniques intended to
                            circumvent, or attempt to circumvent, any restrictions that apply to the
                            Services.
                        </li>
                        <li className={liClassName}>
                            <span className={liTitleClassName}>
                                Intellectual Property Infringement.
                            </span>{" "}
                            To cause the Violate the legal rights (including the rights of publicity
                            and privacy) of others or contain any material that could give rise to
                            any civil or criminal liability under applicable law or regulation or
                            that otherwise may be in conflict with these Terms; engage in
                            transactions involving items that infringe or violate any copyright,
                            trademark, right of publicity or privacy, or any other proprietary right
                            under the law, including but not limited to sales, distribution, or
                            access to counterfeit music, software, or other licensed materials
                            without the appropriate authorization from the rights holder; use of
                            Ethereal’s intellectual property, name, or logo, including use of any
                            Ethereal trade or service mark, without express consent of Ethereal or
                            in a manner that otherwise harms Ethereal or the Ethereal brand; any
                            action that implies an untrue endorsement by or affiliation with
                            Ethereal.
                        </li>
                    </ul>
                    <p className="mt-4">
                        You agree and represent that you will not engage in any Prohibited Use, in
                        connection with the Services. You further represent and warrant that: (a)
                        you will abide by any and all applicable laws of the jurisdiction where you
                        are located, all local, national, and international practices regarding
                        Internet use, and all network agreements, rules, and procedures related to
                        or in connection with the Services; (b) your use of the Services including,
                        without limitation, Digital Asset transactions will be in compliance with
                        applicable law and regulation in your jurisdiction; (c) you have obtained
                        sufficient information about the Services, Digital Assets, and all other
                        services, application, features, or products in connection with the Services
                        to make an informed decisions in regard to your use of the Services; (d) you
                        will bear the full responsibility for any and all activities that occurs in
                        connection with your use or access to the Services including without
                        limitation transactions of Digital Assets, any purchase, interacting with
                        the Services, disclosing, or publishing information, clicking to agree with
                        various agreements, and uploading and submitting various documents or
                        information; and (e) you are the legal and rightful owner of the Digital
                        Assets in the Digital Asset Wallet, and any and all Digital Assets you use
                        in connection with the Services.
                    </p>
                </div>
            ),
        },
        {
            title: "CHANGES, SUSPENSION, AND TERMINATION",
            description: `We may, at our sole discretion and without liability to you, with or without prior notice and at any time, modify or discontinue, temporarily or permanently, all or any part of the Services. You acknowledge that our decision to take certain actions including limiting, suspending, or terminating your access to the Services, may be based on our confidential criteria that are essential to our risk management and security protocols. You agree that we are under no obligation to disclose the details of our risk management and security procedures to you. `,
            subSections: [
                {
                    title: "No Liability",
                    description:
                        "We will not be liable for any losses suffered by you resulting from any modification of the Services or from any suspension or termination of your access to all or a portion of the Services. You acknowledge that Digital Asset values may fluctuate during any period during which the Services have been suspended and agree that Ethereal will have no liability for any such fluctuations. You also acknowledge that in the event of permanent suspension or termination of the Services, the Digital Assets associated with your account may be lost and that we will have no liability for any such losses. You waive and hold harmless Ethereal and its affiliates, licensees, and service providers from any claims resulting from any action taken by Ethereal and/or any of the foregoing parties during, or taken as a consequence of, investigations by us, such parties, or law enforcement authorities. We have the right and sole discretion to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Services",
                },
                {
                    title: "Effect of Termination",
                    description:
                        "In the event of the termination of your right to access or use any part or all of the Services, discontinuation of the Services, or other termination of your right to access any portion of the Services: (a) we may delete or deactivate your account and all related information and/or materials associated with your account without liability to you; (b) we may cancel any open transaction, or other use of the Services that are pending at the time of discontinuation or termination; and (c) any and all amounts payable by you to us will immediately become due.  ",
                },
            ],
        },
        {
            title: "INTELLECTUAL PROPERTY RIGHTS",
            subSections: [
                {
                    title: "Ethereal Materials",
                    description: (
                        <div>
                            The Services and its entire contents, features, and functionality
                            including but not limited to all information, software, text, displays,
                            images, video, and audio, the design, selection, and arrangement
                            thereof, and the “look and feel” of the Services, except any open source
                            software, are owned by Ethereal (“
                            <span className="underline">Ethereal Materials</span>”), its licensors,
                            or other providers of such material and are protected by applicable
                            and/or international copyright, trademark, patent, trade secret, and
                            other intellectual property or proprietary rights laws.
                        </div>
                    ),
                },
                {
                    title: "Limitations on Use",
                    description:
                        "In connection with your use of the Services, you may use the Ethereal Materials solely as authorized by us for as long as we permit you to continue accessing the Services. Without limiting the foregoing, you agree not to: (a) resell, lease, lend, share, distribute, or otherwise permit any third party to use the Services, Ethereal Materials, or use the Services or Ethereal Materials in any service bureau environment; (b) modify or create derivative works of the Services or Ethereal Materials, or any portion thereof, or any data or information received by you in connection therewith; (c) frame, display, or incorporate the Services or Ethereal Materials in any website or any other work of authorship; (d) decompile, disassemble, reverse engineer, or attempt to discover the source code of the Services or Ethereal Materials; (e) use the Services or Ethereal Materials to design, develop, or create any competing product or service; (f) otherwise use the Services or Ethereal Materials for any commercial or noncommercial purpose other than their intended purposes determined at our sole discretion; or (g) suggest, imply, or misrepresent any affiliation, connection, or partnership with, or endorsement by, Ethereal.",
                },
                {
                    title: "Rights We Grant You",
                    description:
                        "We hereby permit you to use and access the Services, provided that you comply with these Terms. If any software, content, or other materials owned or controlled by us are distributed to you as part of your use of the Services, we hereby grant you a non-sublicensable, non-transferable, and non-exclusive right and license to execute, access, and display such software, content, and materials provided to you as part of the Services, in each case for the sole purpose of enabling you to use the Services as permitted by these Terms. ",
                },
                {
                    title: "Reservation of Rights",
                    description:
                        "If your use or access to the Services is in breach of these Terms, your right to access the Services will stop immediately and you must, at our sole option, return or destroy any copies of the materials that you made directly or indirectly from the Services. No right, title, or interest in or to the Services is transferred to you, and all rights not expressly granted are reserved by us. You may freely use any open-source materials up to the limits provided, but in accordance with any requirements placed, by those materials’ open-source licenses. Any use of the Services not expressly permitted by these Terms is a breach of these Terms and may violate copyright, trademark, and other applicable laws.",
                },
                {
                    title: "Trademarks",
                    description: (
                        <div>
                            Our name, the term “<span className="underline">Ethereal</span>,” “
                            <span className="underline">Ethereal.trade</span>,” and any and all
                            related names, logos, product and/or service names, designs, and slogans
                            are trademarks of ours, our affiliates, or licensors. You agree not to
                            use such marks without our prior express written permission.
                        </div>
                    ),
                },
            ],
        },
        {
            title: "PLATFORM CONTENT",
            description:
                "We do not warrant the accuracy, completeness, or usefulness of any materials or information that we or a third party present on or through the Services and such information is made available solely for general information and education purposes. Any information posted to the Services should not be construed as an intention to form a contract, and in no case should any information be construed as an offer from Ethereal to buy, sell, exchange, or otherwise transact with you using Digital Assets. We disclaim all liability and responsibility arising from any reliance placed on such information or materials by you, any other user or person who may be informed of any of the Services contents, or by the actions or omissions of others interacting with the Services.",
        },
        {
            title: "INTERACTIONS WITH OTHER USERS",
            description:
                "You are responsible for your interactions with other users. While we reserve the right to monitor interactions between users, we are not obligated to do so, and we cannot be held liable for your interactions with other users, or for any user’s actions or inactions. If you have a dispute with one or more users, now or in the future, you agree to release us (and our affiliates and subsidiaries, and our and their respective officers, directors, employees, and agents) from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. In entering this release, you expressly waive any protections (whether statutory or otherwise) that would otherwise limit the coverage of this release to include only those claims which you may know or suspect to exist in your favor at the time of agreeing to this release. ",
        },
        {
            title: "PROMOTIONS",
            description:
                "We may make available special offers or conduct promotions for qualifying users. We or a third party may, subject to applicable law, establish qualifying criteria to participate in any special promotion at our sole discretion. You agree that without limitation, we may revoke, modify or discontinue any such special offer at any time and for any reason without advance notice to you. We are under no obligation to make available special offers to all users. We make no recommendation and do not provide any advice about the value or utility of a Digital Asset that is part of a promotion. ",
        },
        {
            title: "FEEDBACK",
            description: (
                <div>
                    Any questions, suggestions, ideas, feedback, reviews, or other information or
                    materials regarding the Services provided by you to Ethereal (collectively, “
                    <span className="underline">Feedback</span>") are non-confidential. Ethereal
                    will be entitled to the unrestricted use and dissemination of Feedback for any
                    purpose, commercial or otherwise without acknowledgment, attribution, or
                    compensation to you. You hereby assign to Ethereal all right, title, and
                    interest to Feedback together with all associated intellectual property rights
                    and waive any claim for, acknowledgement or compensation based on any Feedback
                    or any modifications made based on any Feedback.
                </div>
            ),
        },
        {
            title: "RELATIONSHIP OF THE PARTIES",
            description:
                "Ethereal is not your broker, intermediary, agent, or advisor and has no fiduciary relationship or obligation to you in your use of the Services. Ethereal does not provide investment, tax, or legal advice, and you are solely responsible for any transaction, investment, strategy, decision, or other act that you make when using the Services. We may provide educational material or information on the Platform, through the Services, social media account, or other channel of communication. No communication or information provided to you by Ethereal is intended as, or shall be considered or construed as, advice. To the fullest extent permissible by law, you agree that your access or use of the Services causes Ethereal or any user to owe fiduciary duties or liabilities to you or any third party. Further, you acknowledge and agree to the fullest extent such duties or liabilities are afforded by law or by equity, such duties and liabilities are hereby irrevocably disclaimed, waived, and eliminated, and that we shall be held completely harmless in relation thereof. ",
        },
        {
            title: "CHARGES AND FEES",
            subSections: [
                {
                    title: "Third Party Fees",
                    description:
                        "You may be charged fees for your use or access to the Protocol through a third party interface. You are responsible for doing your own diligence on any third party interface to understand any applicable fee or charge that the third party interface provider may charge you. Under no circumstances shall Ethereal incur any liability, of any kind, to you arising from or relating to fees charged to you by your access or use to the Protocol via a third party interface or any Third Party Services",
                },
                {
                    title: "Our Charges and Fees",
                    description:
                        "We may, in our sole discretion and at any time, set or modify the fees for the Services. If we set or modify fees for the Services, the fee schedule may be available on the Platform. Except when required by law, fees are non-refundable.",
                },
                {
                    title: "Blockchain Fees",
                    description: (
                        <div>
                            Blockchain transactions require the payment of transaction fees to the
                            appropriate network’s nodes, miners, validators, or operators (“
                            <span className="underline">Blockchain Fees</span>”). You will be solely
                            responsible to pay the Blockchain Fees for any transaction that you
                            initiate via the Services. Blockchain Fees are neither levied directly
                            by Ethereal nor paid to or shared with Ethereal in any way, but rather
                            are determined by your use of the Services and the rules placed by
                            corresponding blockchain communities at large. You acknowledge and agree
                            that Ethereal has no control over Blockchain Fees (including without
                            limitation their applicability, payment, amounts, transmission, intended
                            operation, and effectiveness) whether related to your use of the
                            Services or otherwise, and in no event will Ethereal be responsible to
                            you or any other party for the payment, repayment, refund, disbursement,
                            indemnity, or for any other aspect of your use or transmission of
                            Blockchain Fees.
                        </div>
                    ),
                },
            ],
        },
        {
            title: "IDENTITY",
            description: (
                <div>
                    You may be required to provide us with certain information and/or documentation
                    directly or through a third party. You authorize us, directly and/or through a
                    third party, to make inquires that we consider necessary to verify your
                    identity, protect against the misuse of the Services, or any other reason in
                    connection with the Services or related therewith.{" "}
                    <p className="mt-2">
                        You represent and warrant that all information you submit when you register
                        for an account is accurate, current, true, and complete, and you will keep
                        your account information accurate, current, and complete. You are
                        responsible for keeping your account information up to date, including the
                        information that allows you to receive any notices or alerts that we may
                        send you. You are solely responsible for any and all activity that occurs on
                        your account, whether authorized by you or not, and you must keep your
                        account information secure. You will notify us immediately of any breach of
                        security or unauthorized use of your account. You agree that we will have no
                        liability or responsibility for any permanent or temporary inability you may
                        have in connection with your interaction with the Services. You agree to
                        indemnify us for any and all losses incurred as a result of your failure to
                        provide complete, accurate, and up-to-date information at any point prior
                        to, during, and following termination of your use of and access to the
                        Services.
                    </p>
                </div>
            ),
        },
        {
            title: "WARRANTY DISCLAIMER",
            description: (
                <div className="flex-col flex gap-2">
                    <p>
                        ETHEREAL HAS NO OVERSIGHT ON OR CONTROL OVER ANY PARTICULAR DIGITAL ASSET OR
                        BLOCKCHAIN NETWORK. YOU ARE RESPONSIBLE FOR YOUR USE OF THE SERVICES, THE
                        FUNCTIONALITIES THAT YOU ENABLE, TRANSACTIONS ENGAGED ON THE PROTOCOL
                        THROUGH THE SERVICES, AND ACCESS OR USE OF THE INFORMATION DERIVED THEREOF.
                        YOU ARE SOLELY RESPONSIBLE FOR COMPLYING WITH ALL APPLICABLE LAWS RELATED TO
                        ITS TRANSACTIONS AND ACTIVITIES THAT DIRECTLY OR INDIRECTLY INCORPORATE OUR
                        PROVISION OF THE SERVICES. YOU ACKNOWLEDGE AND UNDERSTAND ETHEREAL IS NOT
                        REGISTERED NOR LICENSED WITH, NOR HAVE THE SERVICES OR THE SOFTWARE
                        CONTAINED THEREIN BEEN REVIEWED BY ANY SECURITIES, COMMODITIES, OR OTHER
                        FINANCIAL OR BANKING REGULATOR. YOU FURTHER UNDERSTAND THAT WE CANNOT AND DO
                        NOT GUARANTEE OR WARRANT THAT FILES AVAILABLE FOR DOWNLOAD FROM THE SERVICES
                        WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE RESPONSIBLE FOR
                        IMPLEMENTING SUFFICIENT PROCEDURES AND CHECKPOINTS TO SATISFY YOUR
                        PARTICULAR REQUIREMENTS FOR: (A) AN APPROPRIATE BLOCKCHAIN BASED UTILITY;
                        (B) ANTI-VIRUS PROTECTION AND ACCURACY OF DATA INPUT AND OUTPUT; (C) YOUR
                        PARTICIPATION IN AND USE OF THE PROTOCOL AND RELATED TECHNOLOGIES; AND (D)
                        MAINTAINING A MEANS EXTERNAL TO OUR SITE TO RECONSTRUCT ANY LOST DATA.
                    </p>
                    <p>
                        TO THE FULLEST EXTENT PROVIDED BY LAW, IN NOT EVENT WILL ETHEREAL, ITS
                        AFFILIATES, AND SERVICE PROVIDERS, OR ANY OF THEIR RESPECTIVE OFFICERS,
                        DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES, OR REPRESENTATIVES BE LIABLE
                        FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
                        MAN-IN-THE-MIDDLE ATTACK, VIRUS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL
                        THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER
                        PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICES, PLATFORM, OR ETHEREAL
                        MATERIALS, AND ANY PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF
                        OF ETHEREAL THROUGH THE SERVICES, OR YOUR DOWNLOADING OF ANY MATERIAL POSTED
                        ON IT, OR ON ANY THIRD PARTY WEBSITE LINKED TO IT.
                    </p>
                    <p>
                        YOUR USE OF THE SERVICES AND ANY SERVICES CONTENT IS AT YOUR SOLE RISK. THE
                        SERVICES, ETHEREAL MATERIALS, THE PLATFORM, THE PROTOCOL, AND ANY PRODUCT,
                        SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF ETHEREAL ARE PROVIDED ON
                        AN “AS IS’’ AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT LEGALLY
                        PERMISSIBLE, IN NOT EVENT WILL ETHEREAL, ITS AFFILIATES, AND SERVICE
                        PROVIDERS, OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS, JOINT
                        VENTURERS, EMPLOYEES, OR REPRESENTATIVES BE LIABLE FOR, AND EXPLICITLY
                        DISCLAIM, ANY AND ALL REPRESENTATIONS OR WARRANTIES OF ANY KIND RELATED THE
                        SERVICES, ETHEREAL MATERIALS, THE PROTOCOL, OR PLATFORM, AND ANY PRODUCT,
                        SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF ETHEREAL WHETHER EXPRESS,
                        IMPLIED, OR STATUTORY, INCLUDING (WITHOUT LIMITATION) THE WARRANTIES OF
                        MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE.
                        NEITHER ETHEREAL, ITS AFFILIATES, AND SERVICE PROVIDERS, OR ANY OF THEIR
                        RESPECTIVE OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES, OR
                        REPRESENTATIVES MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                        COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF
                        THE SERVICES, ETHEREAL MATERIALS, PROTOCOL, AND/OR ANY PRODUCT, SERVICE OR
                        OTHER ITEM PROVIDED BY OR ON BEHALF OF ETHEREAL.
                    </p>
                    <p>
                        ETHEREAL, ITS AFFILIATES, AND SERVICE PROVIDERS, OR ANY OF THEIR RESPECTIVE
                        OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES, OR REPRESENTATIVES
                        DO NOT REPRESENT OR WARRANT THAT: (A) ACCESS TO THE SERVICES, ETHEREAL
                        MATERIALS, THE PLATFORM, THE PROTOCOL, AND ANY PRODUCT, SERVICE OR OTHER
                        ITEM PROVIDED BY OR ON BEHALF OF ETHEREAL WILL BE CONTINUOUS, UNINTERRUPTED,
                        TIMELY, WITHOUT DELAY, ERROR-FREE, SECURE, OR FREE FROM DEFECTS; (B) THE
                        INFORMATION CONTAINED OR PRESENTED ON THE SERVICES, ETHEREAL MATERIAL, THE
                        PROTOCOL, OR THE PLATFORM IS ACCURATE, RELIABLE, COMPLETE, CONCISE, CURRENT,
                        OR RELEVANT; (C) THE SERVICES, ETHEREAL MATERIALS, THE PROTOCOL, OR THE
                        PLATFORM, AND ANY PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF
                        ETHEREAL OR ANY SOFTWARE CONTAINED THEREIN WILL BE FREE FROM DEFECTS,
                        MALICIOUS SOFTWARE, ERRORS, OR ANY OTHER HARMFUL ELEMENTS, OR THAT ANY OF
                        SUCH WILL BE CORRECTED; OR (D) THE SERVICES, ETHEREAL MATERIALS, THE
                        PLATFORM, THE PROTOCOL, AND ANY PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY
                        OR ON BEHALF OF ETHEREAL WILL MEET ANY USER’S EXPECTATIONS. NO INFORMATION
                        OR STATEMENT THAT WE MAKE, INCLUDING DOCUMENTATION OR PRIVATE COMMUNICATION,
                        SHOULD BE TREATED AS OFFERING ANY WARRANTY CONCERNING THE SERVICES, ETHEREAL
                        MATERIALS, THE PLATFORM, OR THE PROTOCOL, AND ANY PRODUCT, SERVICE OR OTHER
                        ITEM PROVIDED BY OR ON BEHALF OF ETHEREAL. WE DO NOT ENDORSE, GUARANTEE, OR
                        ASSUME ANY LIABILITY OR RESPONSIBILITY FOR ANY CONTENT, ADVERTISEMENTS,
                        OFFERS, STATEMENTS, OR ACTIONS BY ANY THIRD PARTY EITHER REGARDING THE
                        SERVICES, ETHEREAL MATERIALS, THE PLATFORM, THE PROTOCOL, AND ANY PRODUCT,
                        SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF ETHEREAL. THE FOREGOING
                        DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER
                        APPLICABLE LAW.
                    </p>
                </div>
            ),
        },
        {
            title: "INDEMNIFICATION",
            description:
                "You agrees to defend, indemnify, and hold harmless Ethereal, its affiliates, licensors, and service providers, and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to: (a) your violation of these Terms; (b) your use of Services, including, but not limited to, your interactions with the Platform or the Protocol, and any other service, product, application, or features accessible on or through the Services; (c) use of or reliance on the Platform’s content, the Services, and/or services or products other than as expressly authorized in these Terms; (d) your use or reliance on of any information obtained from the Services; and/or (e) any third party’s access or use of the Services with or without your assistance, using any device, account, profile, Digital Asset Wallet, or other mechanism that you own or control.",
        },
        {
            title: "LIMITATION OF LIABILITY; DISCLAIMER OF DAMAGES",
            description: (
                <div className="flex flex-col gap-2">
                    <p>
                        TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL ETHEREAL, ITS
                        AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
                        OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
                        THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE
                        THE SERVICES, ETHEREAL MATERIALS, THE PLATFORM, THE PROTOCOL, AND/OR ANY
                        PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF ETHEREAL,
                        INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
                        PUNITIVE DAMAGES INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
                        SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF
                        BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                        DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT,
                        OR OTHERWISE, EVEN IF FORESEEABLE. THIS DISCLAIMER OF LIABILITY EXTENDS TO
                        ANY AND ALL DAMAGES CAUSED BY ANY THIRD PARTY (INCLUDING, WITHOUT
                        LIMITATION, THOSE CAUSED BY FRAUD, DECEIT, OR MANIPULATION), WHETHER OR NOT
                        A USER, OR ANY FAILURE, EXPLOIT, OR VULNERABILITY OF THE SERVICES, THE
                        PLATFORM, THE PROTOCOL, ETHEREAL MATERIALS, AND ANY PRODUCT, SERVICE OR
                        OTHER ITEM PROVIDED BY OR ON BEHALF OF ETHEREAL.
                    </p>
                    <p>
                        TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COLLECTIVE
                        LIABILITY OF ETHEREAL, AND ITS SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE
                        PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY
                        (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE)
                        EXCEED THE GREATER OF $100 OR THE AMOUNT YOU HAVE PAID DIRECTLY TO ETHEREAL
                        FOR THE SERVICES IN THE LAST SIX MONTHS OUT OF WHICH LIABILITY AROSE. THE
                        FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED
                        UNDER APPLICABLE LAW.
                    </p>
                </div>
            ),
        },
        {
            title: "DISPUTE RESOLUTION, WAIVER OF CLASS ACTION, AND MANDATORY ARBITRATION",
            description:
                "Please read this section carefully because it waives any right to participate in any class action or other representative action or proceeding. This section requires you to arbitrate certain disputes and limits the ways in which you can seek relief, including by precluding you from suing in court or having a jury trial. ",
            subSections: [
                {
                    title: "Waiver of Class Actions and Right to Jury Trial",
                    description: (
                        <div>
                            To the extent permissible by law, any claim, controversy, or dispute
                            arising out of or related to this Agreement, or any products or services
                            provided in connection with the Services (each a “
                            <span className="underline">Dispute</span>") must be brought in your
                            individual capacity, and not as a plaintiff or class member in any
                            putative class, collective action, or representative proceeding
                            (collectively “ <span className="underline">Class Action Waiver</span>
                            ”). The arbitrator may not consolidate more than one person’s claims or
                            engage in any arbitration on behalf of a class. You agree that, by
                            entering into this Agreement, you are waiving the right to a trial by
                            jury and the right to participate in a class action.
                        </div>
                    ),
                },
                {
                    title: "Informal Resolution",
                    description:
                        "Before filing a claim against Ethereal, you agree to try to resolve the Dispute by first emailing admin@ethereal.trade with a description of your claim and proof of your relationship with Ethereal. If we can’t resolve the Dispute within sixty days of our receipt of your first email, you or Ethereal may then submit the Dispute to binding arbitration as provided herein. ",
                },
                {
                    title: "Arbitration Agreement ",
                    description: (
                        <div>
                            All Disputes between you and Ethereal must be resolved by final and
                            binding arbitration. By agreeing to binding arbitration, you and
                            Ethereal expressly waive the right to formal court proceedings including
                            without limitation trial by jury and class action. This Agreement
                            affects interstate commerce, and the enforceability of this section will
                            be substantively and procedurally governed by the Federal Arbitration
                            Act 9 U.S.C. § 1, et. Seq. (“
                            <span className="underline">FAA</span>”).
                        </div>
                    ),
                },
                {
                    title: "Conducting Arbitration",
                    description: (
                        <div>
                            The arbitration shall be conducted by the International Chamber of
                            Commerce (“<span className="underline">ICC</span>”) under its Commercial
                            Arbitration Rules (“<span className="underline">ICC Rules</span>”) then
                            in effect. If you are a consumer, the most recent version of the ICC
                            Rules can be accessed{" "}
                            <a
                                href="https://iccwbo.org/dispute-resolution/dispute-resolution-services/arbitration/rules-procedure/2021-arbitration-rules/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="underline cursor-pointer"
                            >
                                here
                            </a>
                            . These Terms shall govern any conflict between the ICC Rules and these
                            Terms. The location and type of hearing shall be determined in
                            accordance with the ICC Rules. Further, a party’s right to request a
                            hearing shall also be determined in accordance with the ICC Rules.
                            Unless otherwise ordered by an arbitrator or pursuant to the ICC Rules,
                            any in-person arbitration shall be in English and held remotely to the
                            maximum extent and administered in the Cayman Islands or another
                            mutually agreeable location.
                        </div>
                    ),
                },
                {
                    title: "Confidentiality",
                    description:
                        "Ethereal, the arbitrator, and you, will each maintain the confidentiality of any arbitration proceedings, judgments, and awards including information shared and produced during the arbitration.",
                },
                {
                    title: "Arbitration Time for Filing",
                    description:
                        "Any arbitration must be commenced by filing a demand for arbitration within one year after the date the party asserting the claim first knows or reasonably should know of the act, omission or default giving rise to the claim. If applicable law prohibits a one year limitation period for asserting claims, any claim must be asserted within the shortest time period permitted by applicable law. If a claim is not filed within such period, the Dispute is permanently barred.",
                },
                {
                    title: "Severability",
                    description:
                        "If any portion of this section is found to be unenforceable or unlawful for any reason, the unenforceable or unlawful provision shall be severed from these Terms and such severance of the provision(s) shall have no impact whatsoever on the remainder of this section. Further, to the extent that any claims must therefore proceed on a class, collective, consolidated, or representative basis, such claims must be litigated in a civil court of competent jurisdiction and not in arbitration, and the parties agree that litigation of those claims shall be stayed pending the outcome of any individual claims in arbitration. Lastly, if any provision in this section is found to prohibit an individual claim seeking public injunctive relief, such provision shall have no effect to the extent relief is allowed to be sought outside of arbitration. The remainder of this section shall remain in full force and effect.",
                },
                {
                    title: "Modification",
                    description:
                        "Notwithstanding any term or provision in this Agreement to the contrary, you and Ethereal agree that if Ethereal makes any future material change to this section, we will notify you. Your continued use of the Services including the acceptance of features and services offered following the posting of changes constitutes your acceptance of any such changes.",
                },
            ],
        },
        {
            title: "GOVERNING LAW",
            description:
                "This Agreement shall be governed by, and construed and enforced in accordance with, the laws of the Cayman Islands without regard to conflict of law rules or principles that would cause the application of the laws of any other jurisdiction. You agree that Ethereal may initiate a proceeding relating to the enforceability or validity of Ethereal’s intellectual property rights in any court of competent jurisdiction. With respect to any other proceeding not subject to arbitration under this Agreement, the courts of the Cayman Islands will have exclusive jurisdiction. You waive any objection to venue in any such courts. ",
        },
        {
            title: "AMENDMENTS TO THIS AGREEMENT",
            description:
                "We reserve the right to amend this Agreement, any other agreement or document incorporated or referenced in this Agreement, and any policy governing the use of the Services at any time and in our sole discretion. Any amendment will be effective immediately and you waive any right you may have to receive any specific notice of such amendment. Your continued use of the Services after posting of such amendment constitutes your agreement to be bound by all then-in-effect amendments to this Agreement, regardless of whether you have actually reviewed them.",
        },
        {
            title: "MISCELLANEOUS TERMS",
            subSections: [
                {
                    title: "Assignment",
                    description:
                        "These Terms, and any other document, material, or information referenced herein is particular to you and any attempt that you make to assign, novate, or transfer your rights, interests, liabilities, and/or obligations is null and void, unless you have received Ethereal’s prior written consent. Ethereal reserves the right to assign our rights without restriction, including without limitation to any of our affiliates or subsidiaries, or to any successor in interest of any business associated with the Services. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties and their successors and permitted assigns.",
                },
                {
                    title: "Term and Survival",
                    description:
                        "We reserve the right to change, suspend or discontinue, or terminate, restrict, or disable your use of or access to, parts or all of the Services or their functionality at any time at our sole discretion and without notice. All sections of this Agreement that by their nature should survive termination shall survive termination.",
                },
                {
                    title: "Nonwaiver of Rights",
                    description:
                        "Our failure or delay in exercising any right, power, or privilege under these Terms shall not operate as a waiver thereof.",
                },
                {
                    title: "Headings",
                    description:
                        "Headings of sections are for convenience only and shall not be used to limit or construe such sections.",
                },
                {
                    title: "Severability ",
                    description:
                        "If any provision of this Agreement shall be determined to be invalid or unenforceable under any rule, law, or regulation, or any governmental agency whether local, state, or federal, such provision shall be interpreted to accomplish the objectives of the provision to the greatest extent possible under any applicable law, and the validity or enforceability of any other provision of the Terms shall not be affected.",
                },
                {
                    title: "Force Majeure",
                    description: (
                        <div>
                            <p>
                                You acknowledge and consent that the Services are provided by us
                                according to our current technological capability and other business
                                conditions. While we have made every effort to ensure continuity and
                                security of the Services, we are unable to completely foresee and
                                hedge against all legal, technological, and other risks.
                            </p>
                            <p className="mt-2">
                                Ethereal will not be held liable for delays, failure in performance,
                                or interruption of Services that result directly or indirectly from
                                any cause or condition beyond our reasonable control. Such instances
                                include: (a) acts of God such as earth earthquakes, fires, cyclones,
                                explosions, typhoons, monsoons, landslides, lightning, storms,
                                tempests, pandemics, droughts or meteors; (b) acts of war, whether
                                declared or undeclared, including invasion, act of a foreign enemy,
                                hostilities between nations, civil insurrection, or militarily
                                usurped power; and acts of terrorism; (c) civil disorder, such as
                                acts of a public enemy, malicious damage, terrorism, sabotage, or
                                civil unrest; (d) embargoes or sanctions (such as confiscation,
                                nationalization, requisition, expropriation, prohibition, restraint
                                or damage to property by or under the order of any government or
                                governmental authority; (e) unnatural disasters, such as ionizing
                                radiation or contamination by radioactivity from any nuclear waste
                                or from combustion of nuclear fuel; (f) labor disputes, including
                                strikes, blockades, lock-outs, or other industrial disputes; (g)
                                failure of telecommunication outlets, including the internet,
                                communications networks and facilities, or other infrastructure,
                                systems, operations or of equipment relevant to the provision or use
                                of the Services; (h) data breaches or data-processing failure or
                                incomplete processing; and/or (i) changes in laws or regulations
                                that may materially affect the Digital Assets and/or blockchain
                                industries (collectively, “
                                <span className="underline">Force Majeure Events</span>”).
                            </p>
                        </div>
                    ),
                },
                {
                    title: "Taxes",
                    description:
                        "It is your sole responsibility to determine whether and to what extent any taxes apply to activity you conduct through the Services; and to withhold, collect, report, and remit the correct amounts of taxes to the appropriate tax authorities. No communication or information provided to you by Ethereal is intended as, or considered or construed as, legal or tax advice.",
                },
                {
                    title: "Notice",
                    description:
                        "Any notices or other communications provided by us under these Terms including those regarding modifications to these Terms will be posted online, in the Services, or through other electronic communication. You agree and consent to receive electronically all communications, agreements, documents, notices, and disclosures that we provide in connection with your use of the Services.",
                },
                {
                    title: "Privacy",
                    description: (
                        <div>
                            To understand how Ethereal collects, uses, and shares information about
                            you, please review our [<a href={PRIVACY_ROUTE.path}>Privacy Policy</a>
                            ].
                        </div>
                    ),
                },
                {
                    title: "Third Party Beneficiaries",
                    description:
                        "Nothing in this Agreement, expressed or implied, is intended to confer upon any person, other than the parties and their successors and permitted assigns, any of the rights hereunder.",
                },
                {
                    title: "Entire Agreement",
                    description:
                        "These Terms and every other term or provision applicable to you, including any document incorporated by reference herein, constitute the entire agreement and understanding between you and Ethereal as to the subject matter hereof, and supersede any and all prior discussions, agreements, and understandings of any kind (including any prior versions of these Terms). Unless otherwise specifically stated, these Terms govern and control any conflict between these Terms and any other agreement you may have with Ethereal.",
                },
                {
                    title: "Translation",
                    description:
                        "These Terms are set forth in the English language and all communications including any notices or information being transmitted shall be in English. In the event that these Terms or any part of it is translated (for any proceedings, for your convenience, or otherwise) into any other language, the English language text of these Terms shall prevail.",
                },
            ],
        },
    ];

    const renderSections = () => {
        return terms.map((section, index) => {
            return (
                <div className="flex flex-col mt-4" key={index}>
                    <div className="font-semibold mb-2">
                        {index + 1}. <span className="pl-8 underline">{section.title}</span>
                    </div>
                    <div className="text-sm">{section.description}</div>
                    {section.subSections &&
                        section.subSections.map((subSection, subSectionIdx) => (
                            <div key={`${index}_${subSectionIdx}`}>
                                <div className="font-semibold pl-8 my-4">
                                    {index + 1}.{subSectionIdx + 1}.{" "}
                                    <span className="pl-8">{subSection.title}</span>
                                </div>
                                <div className="text-sm">{subSection.description}</div>
                            </div>
                        ))}
                </div>
            );
        });
    };

    return (
        <div className="gap-8 grid grid-cols-12 w-full">
            <div className="flex flex-col gap-2 text-text-primary col-span-8 col-start-3">
                <div className="flex justify-between items-center mb-10">
                    <h1 className="text-3xl font-semibold">Terms of Use</h1>
                    <div className="text-sm">Last updated on {LAST_UPDATED}</div>
                </div>
                <div className="text-sm">
                    These Terms of Service (these “<span className="underline">Terms</span>" or this
                    “<span className="underline">Agreement</span>") are a contract between you and
                    Ethereal Ltd and its related companies ( "
                    <span className="underline">Ethereal</span>,” “
                    <span className="underline">we</span>
                    ,” “<span className="underline">our</span>,” or “
                    <span className="underline">us</span>") and governs your access or use of our
                    website located at www.ethereal.trade (the “
                    <span className="underline">Platform</span>") and all related services,
                    products, applications, and features offered by us from time to time
                    (collectively, the “<span className="underline">Services</span>"). Your
                    acceptance of these Terms occurs when you access or use the Services, or, if
                    earlier, by clicking on an “I Agree” button or check box presented with these
                    Terms. Upon taking any of the foregoing actions, you agree to be bound by this
                    Agreement and any materials expressly incorporated herein.
                </div>
                <div className="text-sm font-semibold">
                    THESE TERMS INCLUDE A WAIVER OF ANY RIGHT TO PARTICIPATE IN A CLASS ACTION, AS
                    WELL AS A MANDATORY ARBITRATION CLAUSE THAT GOVERNS RESOLUTION OF CERTAIN
                    DISPUTES AND WAIVES YOUR RIGHT TO SUE IN COURT OR HAVE A TRIAL BY JURY.{" "}
                    <span className="underline">PLEASE READ SECTION 21 CAREFULLY.</span>
                </div>
                {renderSections()}
            </div>
        </div>
    );
}
export default TermsPage;
