import axios, { HttpStatusCode } from "axios";
import { config } from "../config";
import toast from "react-hot-toast";

export const api = axios.create({
    baseURL: config.services.apiUrl,
});

export const isGeoBlocked = (error: unknown) =>
    axios.isAxiosError(error) &&
    error.response?.status === HttpStatusCode.Forbidden &&
    error.response?.data.code === "GEO_BLOCKED";

export const isRateLimited = (error: unknown) => {
    return axios.isAxiosError(error) && error.response?.status === HttpStatusCode.TooManyRequests;
};

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (isRateLimited(error)) {
            toast.error("Too many requests. Please try again later", { id: "ratelimit" });
            throw error;
        }
        throw error;
    }
);
