import { ConnectKitButton } from "connectkit";
import React from "react";
import { twMerge } from "tailwind-merge";
interface Props {
    classname?: string;
}

const CustomConnectButton: React.FC<Props> = ({ classname }) => {
    return (
        <ConnectKitButton.Custom>
            {({ isConnected, show, truncatedAddress, ensName }) => {
                return (
                    <button
                        data-testid="connect-btn"
                        onClick={show}
                        className={twMerge(
                            "font-secondary w-full bg-primary p-3 hover:brightness-110 py-2 px-4 md:py-[6px] md:px-[10px] rounded text-base md:text-sm",
                            isConnected
                                ? "bg-transparent border border-border hover:bg-white/5 "
                                : "text-backgroundInversePrimary",
                            classname || ""
                        )}
                    >
                        {isConnected ? ensName || truncatedAddress : "Connect"}
                    </button>
                );
            }}
        </ConnectKitButton.Custom>
    );
};
export default CustomConnectButton;
