import { motion, SpringOptions, useSpring, useTransform } from "framer-motion";
import { useEffect } from "react";
import { useHighlight } from "../../hooks/useHighlight";
import { twMerge } from "tailwind-merge";

interface AnimatedNumberProps {
    value: number;
    suffix?: string;
    className?: string;
    springConfig?: SpringOptions;
}

export function AnimatedNumber({
    value,
    suffix,
    className,
    springConfig = {
        mass: 0.8,
        stiffness: 75,
        damping: 15,
    },
}: AnimatedNumberProps): JSX.Element {
    const springValue = useSpring(0, springConfig);
    const display = useTransform(springValue, (latest) => latest.toFixed(1));
    const highlight = useHighlight(value);

    useEffect(() => {
        springValue.set(value);
    }, [value, springValue]);

    return (
        <div
            className={twMerge(
                "rounded-sm transition-colors",
                className,
                highlight ? "bg-primary/40" : ""
            )}
        >
            $<motion.span className="tabular-nums">{display}</motion.span>
            {suffix}
        </div>
    );
}
