import { routes } from "../routes";
import Logo from "../assets/Logos/Logo.svg";
import LogoFull from "../assets/Logos/LogoFull.svg";
import { useLocation, Link } from "react-router-dom";
import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Button } from "@Catalyst/button";
import { twMerge } from "tailwind-merge";
import { TooltipContent, TooltipProvider, TooltipTrigger } from "./GeneralComponents/Tooltip";
import CustomConnectButton from "./GeneralComponents/CustomConnectButton";
import { BREAKPOINTS, useScreenSize } from "@src/hooks/useScreenSize";
import { Tvl } from "./Season/Tvl";

const NavLink = ({
    path,
    name,
    disabled,
    onOpenDrawerClick,
}: {
    onOpenDrawerClick: (open: boolean) => void;
    path: string;
    name: string;
    disabled?: boolean;
}) => {
    const { pathname } = useLocation();
    const { width } = useScreenSize();

    const renderDisabled = () => {
        return (
            <TooltipProvider placement={width <= BREAKPOINTS.md ? "right" : "bottom"}>
                <TooltipTrigger asChild>
                    <div
                        className={twMerge(
                            "transition-colors rounded p-2",
                            pathname === path ? "text-primary hover:text-primary bg-none" : "",
                            "text-white/50 hover:text-white cursor-default"
                        )}
                    >
                        {name}
                    </div>
                </TooltipTrigger>
                <TooltipContent>
                    <span>Coming soon</span>
                </TooltipContent>
            </TooltipProvider>
        );
    };

    const renderEnabled = () => {
        return (
            <Link
                to={path || ""}
                className={twMerge(
                    "hover:text-primary transition-colors rounded p-2",
                    pathname === path ? "text-primary bg-none cursor-default" : ""
                )}
                onClick={() => onOpenDrawerClick(false)}
            >
                {name}
            </Link>
        );
    };

    return (
        <div className="relative flex md:justify-center items-center">
            {disabled ? renderDisabled() : renderEnabled()}
        </div>
    );
};

function TopNavbar() {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const routesToShow = routes[0]?.children.filter(
        (route) => route.path !== "/" && route.name && !route.hidden
    );

    const renderTitleAndLogo = () => {
        return (
            <>
                {/* desktop */}
                <a className="items-center hidden md:flex" href="/">
                    <img src={LogoFull} alt="" className="h-6 w-fit" draggable={false} />
                </a>
                {/* mobile */}
                <Button
                    data-testid="mobile-menu-btn"
                    outline
                    onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                    className="flex md:hidden items-center md:pointer-events-none !px-[calc(theme(spacing[3])-1px)] !py-[calc(theme(spacing[2])-1px)]"
                >
                    <img src={Logo} alt="" className="size-6 w-auto mr-2" draggable={false} />
                    <Bars3Icon className="size-5 stroke-white" />
                </Button>
            </>
        );
    };

    return (
        <nav className="relative bg-backgroundPrimary/85 w-full p-2 md:px-5 flex-col items-center justify-center flex">
            <div className="max-w-6xl w-full">
                <div className="z-10 w-full flex justify-between">
                    <div className="flex gap-12 items-center">
                        {renderTitleAndLogo()}
                        <div className="text-sm hidden md:flex gap-4">
                            {routesToShow?.map((route) => (
                                <NavLink
                                    key={route.path}
                                    {...route}
                                    onOpenDrawerClick={setIsDrawerOpen}
                                />
                            ))}
                        </div>
                    </div>

                    <div className="justify-center items-center flex ">
                        <Tvl />
                        <CustomConnectButton />
                    </div>
                </div>

                {/* Mobile Side Drawer */}
                <div
                    className={`
                fixed top-0 left-0 h-full w-64 bg-backgroundPrimary transition-transform duration-300 ease-in-out z-30
                ${isDrawerOpen ? "translate-x-0" : "-translate-x-full"}
                md:hidden
            `}
                >
                    <div className="flex flex-col gap-4 p-4 relative text-sm">
                        <button
                            data-testid="mobile-menu-close-button"
                            onClick={() => setIsDrawerOpen(false)}
                            className="absolute right-4 top-4"
                        >
                            <XMarkIcon className="!size-5" />
                        </button>
                        <a className="items-center flex" href="/">
                            <img src={Logo} alt="" className="h-8 w-auto mr-4" draggable={false} />
                            <span className="text-lg font-semibold">Ethereal</span>
                        </a>

                        {routesToShow?.map((route) => (
                            <NavLink
                                key={route.path}
                                {...route}
                                onOpenDrawerClick={setIsDrawerOpen}
                            />
                        ))}
                    </div>
                </div>

                {/* Overlay */}
                {isDrawerOpen && (
                    <div
                        className="fixed inset-0 bg-black/50 z-20 md:hidden"
                        onClick={() => setIsDrawerOpen(false)}
                    />
                )}
            </div>
        </nav>
    );
}

export default TopNavbar;
