import { ContractFunctionExecutionError } from "viem";

interface RpcError {
    code: number;
    message: string;
    [key: string]: unknown; // Allow additional properties
}

export const isRpcError = (error: unknown): error is RpcError => {
    return typeof error === "object" && error !== null && "code" in error && "message" in error;
};

export const isUserRejectedError = (error: unknown) => {
    const errorToUse = error instanceof ContractFunctionExecutionError ? error.walk() : error;
    return isRpcError(errorToUse) && errorToUse.code === 4001;
};
