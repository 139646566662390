const ErrorPage = () => {
    return (
        <div className="max-w-6xl w-full mx-auto">
            <div className="flex flex-col col-span-12">
                <div className="bg-backgroundPrimary col-span-12 flex border border-border rounded h-fit p-8 flex-col items-center text-center mt-12 mx-4">
                    <h2 className="text-2xl mb-4">Something went wrong!</h2>
                    <p className="text-gray-500 mb-8">
                        We've been notified and are looking into the issue.
                    </p>
                    <a
                        href="/"
                        className="bg-backgroundPrimary border border-border rounded px-6 py-3 hover:bg-backgroundPrimary/80 transition-colors"
                    >
                        Return to Home
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
