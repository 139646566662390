import { useTvl } from "../../../hooks/useTvl";
import { formatLargeNumber } from "../../../util/util";
import { AnimatedNumber } from "../../GeneralComponents/AnimatedNumber";

export const Tvl = () => {
    const { data: tvl } = useTvl();
    if (tvl === undefined) return "";
    const formattedTvl = formatLargeNumber(tvl);
    return (
        <div className="md:flex gap-2 mr-4">
            <div className="text-right">TVL</div>
            <AnimatedNumber
                className="text-primary"
                suffix={formattedTvl.suffix}
                value={formattedTvl.value}
            />
        </div>
    );
};
