import "./index.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { useEffect } from "react";
import { useModal } from "connectkit";
import { useAccount } from "wagmi";
import { getSelectedChainId } from "./viemConfig";

function App() {
    const { setOpen } = useModal();
    const { chainId } = useAccount();

    useEffect(() => {
        // Make sure the enforce chain modal closes when valid network is picked
        if (chainId === getSelectedChainId()) {
            setOpen(false);
        }
    }, [chainId]);
    return (
        <div className="min-h-screen">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
