import DepositForm from "@Components/Season/DepositForm";
import DepositInfo from "@Components/Season/DepositInfo";
import FAQs from "@Components/Season/FAQs";
import HoldAndEarn from "@Components/Season/HoldAndEarn";
import SeasonStats from "@Components/Season/SeasonStats";
import PriorityUserAlert from "@Components/Season/PriorityUserAlert";
import { useGlobalContext } from "@src/hooks/Global/useGlobalContext";

function PointsPage() {
    const { referral } = useGlobalContext();

    const isVip = referral?.isVip;

    const sznZeroClassName =
        "text-primary bg-backgroundSecondary text-xs py-1 px-[6px] rounded font-normal ";

    return (
        <div className="gap-8 grid grid-cols-12 w-full">
            {isVip && (
                <div className="col-span-12 mt-7">
                    <PriorityUserAlert />
                </div>
            )}
            <div className="col-span-12">
                <div className="text-2xl md:text-xl py-6 font-semibold flex flex-col md:flex-row items-center md:gap-2 px-4 lg:px-0 justify-center md:justify-start">
                    {/* MOBILE ONLY */}
                    <span className={`${sznZeroClassName}block md:hidden`}>Season Zero</span>
                    Points
                    {/* DESKTOP ONLY */}
                    <span className={`${sznZeroClassName} hidden md:block`}>Season Zero</span>
                </div>
                <div>
                    <SeasonStats />
                </div>
            </div>
            {/* Season Tabs */}
            <div className="flex flex-col col-span-12">
                <div className="bg-backgroundPrimary/85 col-span-12 flex lg:rounded h-fit gap-3 md:flex-row flex-col">
                    <div className="col-span-12 md:col-span-6 flex flex-1">
                        <DepositInfo />
                    </div>
                    <div className="col-span-12 md:col-span-6 flex flex-1 border border-border md:max-w-[460px] rounded m-4">
                        <DepositForm />
                    </div>
                </div>
            </div>

            <div className="bg-backgroundPrimary/85 col-span-12 flex lg:rounded p-4">
                <HoldAndEarn />
            </div>
            <div className="col-span-12 px-4 lg:px-0">
                <FAQs />
            </div>
        </div>
    );
}

export default PointsPage;
