import { createPublicClient, http } from "viem";
import { anvil, mainnet, sepolia } from "viem/chains";
import { config } from "./config";
import { NodeEnvironment } from "./util/interfaces";

const localClient = createPublicClient({ chain: anvil, transport: http("http://localhost:8545") });

const testnetClient = createPublicClient({
    chain: sepolia,
    transport: http(config.services.testnetRpcUrl),
});

const mainnetClient = createPublicClient({
    chain: mainnet,
    transport: http(config.services.mainnetRpcUrl),
});

export const getPublicClient = () => {
    const network = config.services.network;
    if (network === NodeEnvironment.MAINNET) {
        return mainnetClient;
    } else if (network === NodeEnvironment.TESTNET) {
        return testnetClient;
    } else {
        return localClient;
    }
};

export const getSelectedChainId = () => {
    const network = config.services.network;
    if (network === NodeEnvironment.MAINNET) {
        return mainnet.id;
    } else if (network === NodeEnvironment.TESTNET) {
        return sepolia.id;
    } else {
        return anvil.id;
    }
};
