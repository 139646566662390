import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="max-w-6xl w-full mx-auto">
            <div className="flex flex-col col-span-12">
                <div className="bg-backgroundPrimary col-span-12 flex border border-border rounded h-fit p-8 flex-col items-center text-center mt-12 mx-4">
                    <h2 className="text-2xl mb-4">Oops! This page doesn't exist</h2>
                    <p className="text-gray-500 mb-8">
                        The page you're looking for might have been removed, had its name changed or
                        is temporarily unavailable.
                    </p>
                    <Link
                        to="/"
                        className="bg-backgroundPrimary border border-border rounded px-6 py-3 hover:bg-backgroundPrimary/80 transition-colors"
                    >
                        Return Home
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default NotFound;
