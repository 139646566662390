const DepositInfo = () => {
    return (
        <div className="p-4 h-full flex flex-col text-sm">
            <div className="text-xl">1. Earn more with every deposit</div>
            <div className="text-text-secondary flex flex-col gap-4 flex-1 mt-4">
                <p className="text-text-secondary">
                    <span className="text-text-primary">
                        Deposit USDe into Ethereal and receive eUSDe receipt tokens.
                    </span>{" "}
                    Hold to accumulate Ethereal points - and as a{" "}
                    <span className="text-text-primary">
                        bonus unlock the maximum 30x Ethena Rewards
                    </span>{" "}
                    multiplier on your deposit. Plus use eUSDe in supported integrations (soon) to
                    amplify your rewards even further.
                </p>
                <p className="text-text-secondary">
                    Ethereal Points are distributed linearly, scaling with your deposit size and
                    duration—the{" "}
                    <span className="text-text-primary">more you deposit, the more you earn.</span>
                </p>
            </div>
        </div>
    );
};

export default DepositInfo;
