import { formatUnits } from "viem";
import { AccountDto } from "./interfaces";
import { config } from "@src/config";
import { formatNumberTwoDecimalsFromString } from "./util";

export const getAccountByAssetAddress = (
    accounts: AccountDto[] | null,
    assetAddress: string
): AccountDto | null => {
    if (!accounts || accounts.length === 0) return null;
    const matchingAsset = accounts.find(
        (account) => account.assetAddress.toLowerCase() === assetAddress.toLowerCase()
    );
    return matchingAsset || null;
};

export const getAccountBalanceByAssetAddress = (
    accounts: AccountDto[] | null,
    assetAddress: string
): string => {
    const account = getAccountByAssetAddress(accounts, assetAddress);
    const balance = account
        ? formatUnits(BigInt(account.balance), config.contracts.vault.assetToken.decimals)
        : "0";
    return formatNumberTwoDecimalsFromString(balance);
};

export const getAccountPointsByAssetAddress = (
    accounts: AccountDto[] | null,
    assetAddress: string
): string => {
    const account = getAccountByAssetAddress(accounts, assetAddress);
    return account ? formatNumberTwoDecimalsFromString(account.points) : "0";
};

export const toReadableNumber = (val: string, precision?: number): number => {
    if (!precision) {
        precision = config.decimalDisplayPrecision;
    }

    return (
        Number(
            BigInt(val) / BigInt(10 ** (config.contracts.vault.assetToken.decimals - precision))
        ) /
        10 ** precision
    );
};
