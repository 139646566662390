import { indexToLetter } from "@src/util/util";
import { createRef, ReactNode, RefObject, useRef } from "react";

interface Section {
    title: string;
    description: ReactNode;
    bullets: {
        title: string;
        description: string;
        postText?: string | ReactNode;
        subBullets?: {
            title: string;
            description: string;
        }[];
    }[];
}

function PrivacyPage() {
    const LAST_UPDATED = "February 7, 2025";
    const privacyPolicy: Section[] = [
        {
            title: "Information We Collect",
            description: (
                <>
                    Depending on how you interact with the Services, we may collect information
                    about you like:
                </>
            ),
            bullets: [
                {
                    title: "Information You Provide",
                    description:
                        "You may provide us with information when, for instance, you connect your blockchain wallet or communicate with us via email.",
                },
                {
                    title: "Information Collected Automatically",
                    description:
                        "We may automatically collect information about your interactions with the Services when you view or click content on the Services.",
                },
                {
                    title: "Information from Third Parties",
                    description:
                        "We may receive information about you from third parties, when, for example, we analyze public blockchain data and wallet addresses.",
                    postText:
                        "In connection with using or accessing the Services, we may collect the following categories of information.",
                },
                {
                    title: "Communication Information",
                    description: "Information about communications you submit to us.",
                },
                {
                    title: "Blockchain Data",
                    description:
                        "Publicly available blockchain data like wallet addresses and transaction IDs.",
                },
                {
                    title: "Device Data",
                    description:
                        "Device type and other data about your device like its software and hardware details, browser type and version, and operating system.",
                },
                {
                    title: "Third Party Connections and Integrations",
                    description:
                        "If you access the Services through a third party connection, you may allow us to have access to certain information from such third party.",
                },
                {
                    title: "Other Information",
                    description:
                        "Any other information that you choose to provide or that we request.",
                    postText:
                        "We may aggregate or de-identify information so that the information can no longer identify you, as defined under applicable laws. We may use and/or disclose aggregated or de-identified information for any purpose unless an applicable law says otherwise.",
                },
            ],
        },
        {
            title: "How We Use Your Information",
            description: (
                <p>
                    We may use the information we collect in connection with what we consider our
                    <span className="font-bold ml-2">Everyday Business Activities</span> including
                    using your information to:
                </p>
            ),
            bullets: [
                {
                    title: "Operate and Provide the Services",
                    description:
                        "We may use the information we collect to provide and operate the Services like contacting you about technical notices or security alerts, send important notices, market the Services, or provide other relevant information.",
                },
                {
                    title: "Facilitate a Corporate Transaction",
                    description:
                        "We may use information we collect during negotiations or in connection with the sale of part or all of our assets, the acquisition of part or all of another business or the businesses' assets, or other transaction including but not limited to financing or bankruptcy.",
                },
                {
                    title: "Foster Safety and Security",
                    description:
                        "We use the information we collect to provide for the safety and security of individuals, employees, and Ethereal, including to prevent fraud or illegal activities, improving our security, and exercising our legal rights.",
                },
            ],
        },
        {
            title: "Sharing Information",
            description: (
                <div>
                    You should know the ways that we share your information and why we share your
                    information. In connection with our{" "}
                    <span
                        className="ml-2 underline font-semibold cursor-pointer"
                        onClick={() => scrollToSection("How We Use Your Information")}
                    >
                        Everyday Business Activities{" "}
                    </span>
                    , we may share information collected from or about you in the below
                    circumstances.
                </div>
            ),
            bullets: [
                {
                    title: "Service Providers",
                    description:
                        "We may share your information with services providers that perform functions or provide services on our behalf such as our business, professional, or technical support providers that help us with our business and the Services or administer activities in connection with the Services or our business.",
                },
                {
                    title: "Comply with Laws and Protect our Rights",
                    description:
                        "When we believe it to be necessary or appropriate to comply with applicable law and legal processes, protect our rights, privacy, safety, or property, and that of our affiliates, you, or others, or for loss prevention.",
                },
                {
                    title: "Others",
                    description:
                        "We may share your information with third parties when you direct us to or if you consented to additional sharing of your information.",
                },
            ],
        },
        {
            title: "Cookies and Similar Technologies",
            description: (
                <div>
                    Like most other online services, we use cookies and similar technologies to
                    collect some of the data discussed in this Privacy Policy. These help us provide
                    certain features to you and analyze usage information. The
                    <span
                        className="mx-2 underline font-semibold cursor-pointer"
                        onClick={() => scrollToSection("Your Choices")}
                    >
                        Your Choices
                    </span>
                    section below provides additional information about our use of cookies and
                    similar technologies and your ability to manage cookies.{" "}
                    <span className="block my-2">
                        Our use of cookies and similar technologies fall into the following general
                        categories:
                    </span>
                </div>
            ),
            bullets: [
                {
                    title: "Essential",
                    description:
                        "We use cookies, web beacons, or other similar technologies necessary to provide the Services. This includes technologies that allow you to access the Services or required to identify irregular site behavior and improve security.",
                },
                {
                    title: "Functionality",
                    description:
                        "We may use cookies, web beacons, or other similar technologies that allow us to offer you enhanced functionality when accessing or using the Services. This may include delivering error messages and enhancing the presentation of the Services content.",
                    postText:
                        "Ethereal does not generally treat the information collected by these cookies and similar technologies as personal information. However, to the extent that IP addresses or similar identifiers are considered personal information by applicable law, we also treat these identifiers as personal information in such jurisdictions. ",
                },
            ],
        },
        {
            title: "Your Choices",
            description: (
                <>
                    You have certain choices about how to protect and limit the collection, use, and
                    sharing of information about you including:
                </>
            ),
            bullets: [
                {
                    title: "Opt-out of Cookies and Tracking Technologies",
                    description:
                        "You can often adjust your browser setting to remove or reject browser cookies. If you remove or reject cookies, the availability and functionality of the Services may be affected. You may also limit or block cookies and similar tracking technologies by:",
                    subBullets: [
                        {
                            title: "Using Privacy Plug-Ins or Browsers",
                            description:
                                "You can block websites from setting cookies used for interest-based ads by using a browser with privacy features or installing browser plugins and configuring them to block third party cookies or trackers.",
                        },
                        {
                            title: "Do Not Track",
                            description: `Please note that while we provide tools to manage your privacy as described in this Privacy Policy, we do not support "Do Not Track" browser settings at this time.`,
                        },
                    ],
                },
                {
                    title: "Email",
                    description:
                        "We offer a variety of commercial emails and email newsletters. You can unsubscribe from them by following the instructions near the bottom of the email.",
                    postText: (
                        <div>
                            Where required by applicable law and depending on your jurisdiction, you
                            may have access to certain rights. For example, the right to obtain
                            confirmation that we maintain certain personal information relating to
                            you, to verify its content, origin, and accuracy, as well as the right
                            to access, review, port, delete, or block or withdraw consent to the
                            processing of certain personal information (without affecting the
                            lawfulness of processing based on consent before its withdrawal). If you
                            are unable to exercise your rights using the mechanisms in the{" "}
                            <span
                                className="ml-2 underline font-semibold cursor-pointer"
                                onClick={() => scrollToSection("Your Choices")}
                            >
                                Your Choices
                            </span>{" "}
                            section, you may email us at admin@ethereal.trade. We may ask you to
                            provide us with information, such as your name and email address,
                            necessary to reasonably verify your identity before responding to your
                            request. If we can’t verify your identity, we will not be able to
                            fulfill your request.
                        </div>
                    ),
                    subBullets: [],
                },
            ],
        },
        {
            title: "International Transfers",
            description: (
                <>
                    Your information may be transferred to a country other than the country you are
                    located. We comply with laws on the transfer of personal information between
                    countries to help ensure your information is protected, wherever it may be.
                </>
            ),
            bullets: [],
        },
        {
            title: "How We Protect Your Information",
            description: (
                <>
                    We use reasonable procedural, physical, and electronic safeguards to protect
                    your information from unauthorized access or use including encryption at rest
                    and during transit. Our measures are designed to protect against the accidental
                    or unlawful destruction, loss, misuse, alteration, and unauthorized disclosure
                    of, or access to, the information under our control. The safety and security of
                    your information also depends on you, do not share your private cryptographic
                    key with anyone.
                </>
            ),
            bullets: [],
        },
        {
            title: "Information Storage",
            description: (
                <>
                    We retain your information for as long as necessary to provide the Services,
                    fulfill our legal obligations, enforce our legal agreements, and resolve
                    disputes. The retention periods for your information are determined on a
                    case-by-case basis depending on the nature of information, why it was collected,
                    and the applicable legal reason for the retention. We may also keep your
                    information when necessary to protect the safety and security of the Services.
                </>
            ),
            bullets: [],
        },
        {
            title: "Age Restrictions",
            description: (
                <div>
                    The Services are not intended for anyone under the age of 18. We do not
                    knowingly collect or solicit personal information from children. In the event
                    that we encounter information from an individual under the age of 18, we will
                    promptly take steps to delete such information. If you believe your child under
                    the age of 18 uploaded information in connection with the Services, please
                    contact us using the information in the{" "}
                    <span
                        className="underline font-semibold cursor-pointer"
                        onClick={() => scrollToSection("Contact Us")}
                    >
                        Contact Us{" "}
                    </span>{" "}
                    section below.
                </div>
            ),
            bullets: [],
        },
        {
            title: "Changes to this Privacy Policy",
            description: (
                <>
                    We are always improving the Services and create new features regularly. These
                    updates sometimes require us to collect new information or use what we already
                    have differently. Further, the laws regarding personal information and privacy
                    frequently change. We will notify you of any changes we make by revising the
                    date at the top of this page. If you object to any change, you can stop using
                    the Services. After we post any changes, your continued use of the Services is
                    subject to the updated Privacy Policy.
                </>
            ),
            bullets: [],
        },
        {
            title: "Third Party Links and Features",
            description: (
                <>
                    The Services may contain links, banners, and/or widgets to third party sites,
                    applications, or services. We may also incorporate third party software
                    (including open source software) in the Services. These links and features are
                    provided for your reference and convenience only and do not imply any
                    endorsement of information provided through these third party links and
                    features, nor any association with their operators. Ethereal is not responsible
                    for the practices of such third parties. Any information you provide to third
                    parties on their sites or services is covered under their privacy and data
                    collection practices and not covered by this Privacy Policy.
                </>
            ),
            bullets: [],
        },
        {
            title: "Contact Us",
            description: <>If you have any questions, please email us at admin@ethereal.trade.</>,
            bullets: [],
        },
    ];
    // Create a map of refs for each section
    const refs = useRef<Map<string, RefObject<HTMLDivElement>>>(
        new Map(privacyPolicy.map((section) => [section.title, createRef<HTMLDivElement>()]))
    );

    const scrollToSection = (id: string) => {
        refs.current.get(id)?.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    };

    const renderSection = (section: Section, index: number) => {
        return (
            <div key={index} className="mt-8" ref={refs.current.get(section.title)}>
                <h2 className="font-semibold mb-2">
                    <span className="mr-8">{index + 1}.</span>
                    <span className="underline">{section.title} </span>
                </h2>
                <div className="mb-2">{section.description}</div>

                {section.bullets.map((bullet, bulletIdx) => {
                    const hasSubBullets = bullet.subBullets;
                    if (hasSubBullets) {
                        return (
                            <div key={`${index}_bullet_${bulletIdx}`}>
                                <div className="mb-2">
                                    <span className="mr-2 font-semibold">
                                        {indexToLetter(bulletIdx)}. {bullet.title}.
                                    </span>
                                    {bullet.description}
                                </div>
                                {bullet.subBullets!.map((subBullet, subBulletIdx) => (
                                    <ul
                                        className="list-disc pl-10"
                                        key={`${index}_bullet_${bulletIdx}_${subBulletIdx}`}
                                    >
                                        <li className="mb-2">
                                            <span className="underline mr-2">
                                                {subBullet.title}{" "}
                                            </span>
                                            {subBullet.description}
                                        </li>
                                    </ul>
                                ))}
                                {bullet.postText && <div className="my-4">{bullet.postText}</div>}
                            </div>
                        );
                    }
                    return (
                        <div key={`${index}_bullet_${bulletIdx}`}>
                            <ul className="list-disc pl-10">
                                <li className="mb-2">
                                    <span className="underline mr-2">{bullet.title}.</span>
                                    {bullet.description}
                                </li>
                            </ul>
                            {bullet.postText && <div className="my-4">{bullet.postText}</div>}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="gap-8 grid grid-cols-12 w-full">
            <div className="flex flex-col gap-2 text-text-primary col-span-8 col-start-3">
                <div className="flex justify-between items-center mb-10">
                    <h1 className="text-3xl font-semibold">Privacy Policies</h1>
                    <div className="text-sm">Last updated on {LAST_UPDATED}</div>
                </div>
                <div className="text-sm">
                    This Privacy Policy describes how Ethereal Ltd (“
                    <span className="underline">Ethereal</span>,” “
                    <span className="underline">we</span>
                    ,” “<span className="underline">our</span>,” or “
                    <span className="underline">us</span>”) may collect, use, and disclose personal
                    information about you. This Privacy Policy applies to information collected when
                    you interact with the “Services” we offer, which include our website, services,
                    products, software, and applications.
                </div>
                <div className="text-sm">
                    Please read this Privacy Policy carefully so that you understand our policies
                    and practices regarding your information. If you do not agree with our policies
                    and practices then your choice is not to interact with the Services. By
                    accessing or using the Services you agree to this Privacy Policy. We encourage
                    you to check this Privacy Policy often so you stay informed about our practices
                    and the choices available to you.
                </div>
                <div className="text-sm">{privacyPolicy.map(renderSection)}</div>
            </div>
        </div>
    );
}
export default PrivacyPage;
