import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
} from "@heroicons/react/24/solid";
import toast, { resolveValue, Toast, Toaster } from "react-hot-toast";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { truncate } from "@src/util/util";
import { TOAST_MAX_CHARS } from "@src/util/constants";
import { useState } from "react";

const CustomToaster = () => {
    const [showFull, setShowFull] = useState<{ [toastId: string]: boolean }>({});

    const renderToast = (t: Toast) => {
        const fullMsgRenderable = resolveValue(t.message, t);
        const fullMsg = fullMsgRenderable?.toString() || "";
        const truncated = truncate(fullMsg, TOAST_MAX_CHARS);
        let defaultClass =
            "flex items-start gap-2 p-4 w-[390px] text-white border text-sm overflow-hidden rounded-[4px] max-h-[90vh]";

        const toastBody = (
            <div className="max-w-[340px] overflow-y-auto max-h-full">
                <div className="mr-3">
                    <div className="flex-1">{showFull[t.id] ? fullMsg : truncated}</div>
                    {fullMsg.length > TOAST_MAX_CHARS && (
                        <button
                            data-testid="toast-show-more-btn"
                            className="border-b border-dashed mt-4"
                            onClick={() =>
                                setShowFull((prev) => {
                                    return {
                                        ...prev,
                                        [t.id]: !prev[t.id],
                                    };
                                })
                            }
                        >
                            {showFull[t.id] ? "Show Less" : "Show More"}
                        </button>
                    )}
                </div>
                <button
                    data-testid="close-toast-btn"
                    className="absolute top-2 right-2"
                    onClick={() => toast.remove(t.id)}
                >
                    <XMarkIcon className="!size-5 stroke-text-secondary" />
                </button>
            </div>
        );

        if (t.type === "success") {
            defaultClass = `${defaultClass} bg-toastBgSuccess  border-[#1C3B28]`;
            return (
                <div className={defaultClass}>
                    <div className="h-full">
                        <CheckCircleIcon className="size-5 shrink-0 text-success" />
                    </div>
                    {toastBody}
                </div>
            );
        } else if (t.type === "error") {
            defaultClass = `${defaultClass} bg-toastBgError border-[#4E2020]`;
            return (
                <div className={defaultClass}>
                    <div className="h-full">
                        <ExclamationCircleIcon className="size-5 shrink-0 text-background-red" />
                    </div>
                    {toastBody}
                </div>
            );
        }
        defaultClass = `${defaultClass} bg-backgroundPrimary border-4 border-border`;
        return (
            <div className={defaultClass}>
                <div className="h-full">
                    <InformationCircleIcon className="size-5 shrink-0 text-primary" />
                </div>
                {toastBody}
            </div>
        );
    };
    return (
        <Toaster
            position="bottom-right"
            toastOptions={{
                duration: 10000,
            }}
        >
            {(t) => renderToast(t)}
        </Toaster>
    );
};

export default CustomToaster;
