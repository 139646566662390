import { Button } from "@Catalyst/button";
import { Dialog, DialogBody } from "@Catalyst/dialog";
import { Checkbox, CheckboxField } from "@Catalyst/checkbox";
import { Label } from "@Catalyst/fieldset";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { nowInNanoSeconds } from "@src/util/util";
import { useAccount } from "wagmi";
import { disconnect, signTypedData } from "@wagmi/core";
import { getSelectedChainId } from "@src/viemConfig";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { wagmiConfig } from "@src/main";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import InfoTriangle from "@src/assets/information-triangle-amber.svg";
import { config } from "@src/config";
import { ReferralDto } from "@src/util/interfaces";
import toast from "react-hot-toast";
import { api, isGeoBlocked } from "../util/api";
import axios, { AxiosError, HttpStatusCode } from "axios";
import { isUserRejectedError } from "../util/error";
import { Divider } from "@Catalyst/divider";
import { Badge } from "./Catalyst/badge";
import { twJoin } from "tailwind-merge";
import { PRIVACY_ROUTE, TERMS_ROUTE } from "@src/routes";
import { useGlobalContext } from "@src/hooks/Global/useGlobalContext";

interface Props {
    setReferral: Dispatch<SetStateAction<ReferralDto | null>>;
    geoBlocked: boolean;
}
const TermsOfServiceDialog: React.FC<Props> = ({ setReferral, geoBlocked }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [accept, setAccept] = useState(false);
    const [pendingTxn, setPendingTxn] = useState(false);

    const { accounts } = useGlobalContext();
    const { address, isConnected } = useAccount();
    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();

    const referralParam = "ref";
    const referralCodeParam = searchParams.get(referralParam);
    const selectedChainId = getSelectedChainId();

    const hideDialog = [TERMS_ROUTE.path, PRIVACY_ROUTE.path].includes(pathname);

    const removeReferralFromUrl = () => {
        if (!searchParams.has(referralParam)) {
            return;
        }
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(referralParam);
        navigate(
            { pathname: location.pathname, search: newSearchParams.toString() },
            { replace: true }
        );
    };

    const isNonexistentCode = (errMsg: string) => {
        try {
            return errMsg.toLowerCase().includes("does not exist");
        } catch (err) {
            // Log what the error is
            console.error("Error processing errMsg:", err, "Original message:", errMsg);
            return false;
        }
    };

    // remove referral code from url if account exists (i.e. don't allow them to add a new referral code if they've already deposited)
    useEffect(() => {
        if (accounts && accounts.length > 0) {
            removeReferralFromUrl();
        }
    }, [accounts]);

    // reset states when wallet changes
    useEffect(() => {
        setAccept(false);
        setPendingTxn(false);
        getReferralDbRecord();
        if (!isConnected) {
            setOpen(false);
        }
    }, [address]);

    async function getReferralDbRecord() {
        if (!address) {
            setReferral(null);
            return;
        }

        try {
            const res = await api.get(`/referral/${address}`);
            const referralResponse: ReferralDto = res.data;
            setReferral(referralResponse);
            setOpen(!referralResponse.hasSignedTos);
        } catch (error) {
            console.error(`Error fetching referral code: ${error}`);
            if (
                (axios.isAxiosError(error) && error.response?.status === HttpStatusCode.NotFound) ||
                isGeoBlocked(error)
            ) {
                setReferral(null);
                setOpen(true);
            }
        }
    }

    async function signReferralMsg(refCode: string | null | undefined) {
        // normalize the referral code to uppercase
        const referralCode = refCode?.toUpperCase();
        setPendingTxn(true);
        try {
            const sender = address;
            const nonce = nowInNanoSeconds();
            const ADD_REFERRAL_CODE = "AddReferralCode";
            const TOS = "ToS";

            const types = referralCode
                ? {
                      [ADD_REFERRAL_CODE]: [
                          { name: "sender", type: "address" },
                          { name: "referralCode", type: "string" },
                          { name: "nonce", type: "uint64" },
                      ],
                  }
                : {
                      [TOS]: [
                          { name: "sender", type: "address" },
                          { name: "nonce", type: "uint64" },
                      ],
                  };

            const message = referralCode ? { sender, referralCode, nonce } : { sender, nonce };

            const domain = {
                name: referralCode ? ADD_REFERRAL_CODE : TOS,
                version: "1",
                chainId: selectedChainId,
                verifyingContract: config.contracts.vault.address,
            };

            const signature = await signTypedData(wagmiConfig, {
                account: sender,
                domain,
                types,
                primaryType: referralCode ? ADD_REFERRAL_CODE : TOS,
                message,
            });
            const res = await api.post(`/referral/addReferralCode`, {
                data: { sender, referralCode, nonce },
                signature,
            });
            if (res.status === HttpStatusCode.Created || res.data === true) {
                toast.success(
                    referralCode
                        ? "Successfully signed terms of service and added referral code"
                        : `Successfully signed terms of service`
                );
                await getReferralDbRecord();
                setOpen(false);
            } else {
                console.error("Error signing terms of service:", res.data);
                toast.error(`Error signing terms of service: ${res.data}`);
            }
        } catch (error) {
            console.error("Error signing terms of service:", error);
            if (isUserRejectedError(error)) {
                toast.error(
                    "Accepting terms of service is required, please connect your wallet and try again"
                );
            } else {
                if (
                    error instanceof AxiosError &&
                    isNonexistentCode(error.response?.data?.message || "")
                ) {
                    removeReferralFromUrl();
                }
                toast.error(
                    `Error signing terms of service: ${error instanceof AxiosError ? error.response?.data.message : error}`
                );
            }
            setAccept(false);
            await disconnect(wagmiConfig);
        } finally {
            setPendingTxn(false);
        }
    }

    const handleAccept = async () => {
        if (!address || pendingTxn) return;
        await signReferralMsg(referralCodeParam);
    };

    const handleSignout = async () => {
        await disconnect(wagmiConfig);
    };

    if (hideDialog) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={() => {}} // prevents closing on click out of dialog
            className={`!bg-backgroundPrimary !p-0 fixed overflow-hidden flex flex-col top-1/2 text-text-primary 
                left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] max-w-[90%] md:max-w-lg mx-auto !rounded-md`}
        >
            <div className="p-4">
                {geoBlocked ? "Unavailable in your region" : "Terms of use and privacy"}
            </div>
            <Divider />
            <DialogBody className="p-4 !my-0">
                {geoBlocked ? (
                    <Badge className="mt-2 mb-5" color="amber">
                        {/* <ExclamationTriangleIcon className="size-5 shrink-0 text-amber" /> */}
                        <img src={InfoTriangle} alt="" className="size-5 mr-2" draggable={false} />
                        Ethereal is not available to residents, citizens or companies incorporated
                        in the United States of America, Australia and other restricted regions.
                    </Badge>
                ) : (
                    <div className="mb-5">
                        <div className="mb-6">
                            To continue, please review and agree to the following:
                        </div>
                        <CheckboxField className="flex justify-start !items-start">
                            <Checkbox
                                disabled={geoBlocked}
                                name="accept"
                                onChange={() => setAccept(!accept)}
                                checked={accept}
                                className={twJoin("mt-1", geoBlocked ? "opacity-80" : "")}
                                data-testid="accept-checkbox"
                            />
                            <Label
                                className="text-sm font-secondary"
                                onClick={(e) => e.stopPropagation()}
                            >
                                I confirm that I have read, understood, and accepted the{" "}
                                <a
                                    className="text-primary"
                                    href={TERMS_ROUTE.path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    Terms of Use{" "}
                                </a>{" "}
                                and{" "}
                                <a
                                    className="text-primary z-20"
                                    href={PRIVACY_ROUTE.path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    Privacy Policy
                                </a>
                                .
                            </Label>
                        </CheckboxField>
                    </div>
                )}
                {!geoBlocked && (
                    <Button
                        disabled={!accept || pendingTxn}
                        onClick={handleAccept}
                        className="w-full mb-2"
                        data-testid="accept-button"
                    >
                        Agree & continue
                        {pendingTxn && <ArrowPathIcon className="!w-5 !h-5 mr-2 animate-spin " />}
                    </Button>
                )}
                <Button
                    outline
                    data-testid="sign-out-btn"
                    className="w-full"
                    onClick={handleSignout}
                >
                    Sign out
                </Button>
            </DialogBody>
        </Dialog>
    );
};
export default TermsOfServiceDialog;
