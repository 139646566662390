import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { anvil, sepolia, mainnet, Chain } from "viem/chains";
import { createConfig, fallback, http, injected, unstable_connector, WagmiProvider } from "wagmi";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { config } from "./config";
import walletModalTheme from "../walletModalTheme.json";
import { getSelectedChainId } from "./viemConfig";
import * as Sentry from "@sentry/react";
import ErrorPage from "./pages/Error/ErrorPage";
import { NodeEnvironment } from "./util/interfaces";

const getChainsByChainId = (chainId: number): readonly [Chain] => {
    const chainMap: Record<number, readonly [Chain]> = {
        [anvil.id]: [anvil],
        [sepolia.id]: [sepolia],
        [mainnet.id]: [mainnet],
    };

    return chainMap[chainId] || [mainnet];
};

export const wagmiConfig = createConfig(
    getDefaultConfig({
        chains: getChainsByChainId(getSelectedChainId()),
        transports: {
            [mainnet.id]: fallback([
                unstable_connector(injected, { retryCount: 0 }),
                http(config.services.mainnetRpcUrl),
            ]),
            [sepolia.id]: fallback([unstable_connector(injected, { retryCount: 0 }), http()]),
            [anvil.id]: http("http://localhost:8545"),
        },
        // Required API Keys
        walletConnectProjectId: config.services.walletConnectProjectId,

        // Required App Info
        appName: "Ethereal",
        appUrl:
            config.services.network === NodeEnvironment.TESTNET
                ? "https://deposit.etherealtest.net"
                : "https://deposit.ethereal.trade",
        appIcon: "logo.svg",
    })
);

Sentry.init({
    dsn: "https://0f2fde016dc0d1a3a1d459c7bdfe521c@o4508780671860736.ingest.de.sentry.io/4508780674809936",
    integrations: [],
    enabled: config.isProd,
    // Potentially enable Tracing
    // integrations: [Sentry.browserTracingIntegration()],
    // tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
});

const queryClient = new QueryClient();

createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
            <WagmiProvider config={wagmiConfig}>
                <QueryClientProvider client={queryClient}>
                    <ConnectKitProvider
                        customTheme={walletModalTheme}
                        options={{
                            initialChainId: getSelectedChainId(),
                            enforceSupportedChains: true,
                        }}
                    >
                        <App />
                    </ConnectKitProvider>
                </QueryClientProvider>
            </WagmiProvider>
        </Sentry.ErrorBoundary>
    </StrictMode>
);
