import { useGlobalContext } from "@src/hooks/Global/useGlobalContext";
import AssetTable from "../AssetTable";
import ReferRow from "../ReferRow";
import InfoCircle from "@src/assets/information-circle.svg";
import { formatNumberTwoDecimalsFromString } from "@src/util/util";
import {
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@src/Components/GeneralComponents/Tooltip";

const HoldAndEarn = () => {
    const { eUsdebalance, lpPendleBalance, ytPendleBalance } = useGlobalContext();
    const pendleTotal = Number(lpPendleBalance) + Number(ytPendleBalance);
    const eUsdeTotal = Number(eUsdebalance) + Number(pendleTotal);

    return (
        <div className="w-full flex flex-col">
            <div className="flex flex-col md:flex-row md:gap-12 gap-4 mb-4 text-sm">
                <div>
                    <h1 className="text-xl">2. Hold & earn – maximize your rewards</h1>
                    <div className="text-text-secondary flex flex-col gap-4 flex-1 mt-4">
                        <p className="text-text-secondary">
                            Simply holding eUSDe - after pre-depositing - earns you Ethereal points
                            plus the bonus 30x Ethena Rewards multiplier on that deposit.
                        </p>
                        <p className="text-text-secondary">
                            Coming soon: Maximize your Ethereal points with eUSDe-supported
                            integrations, unlocking even greater rewards. Stay tuned!
                        </p>
                    </div>
                </div>
                <div className="w-full md:w-[280px] md:min-w-[280px] border-border border flex justify-center flex-col items-center rounded p-2 py-6">
                    <div className="flex gap-1">
                        <div className="text-text-secondary">Total eUSDe</div>
                        <TooltipProvider>
                            <TooltipTrigger>
                                <img src={InfoCircle} draggable={false} />

                                <TooltipContent>
                                    Total equivalent USDe pre-deposited into Ethereal
                                </TooltipContent>
                            </TooltipTrigger>
                        </TooltipProvider>
                    </div>
                    <div className="text-2xl font-medium flex items-center justify-center text-center">
                        {formatNumberTwoDecimalsFromString(`${eUsdeTotal}`)}
                    </div>
                </div>
            </div>
            <AssetTable />
            <ReferRow />
        </div>
    );
};

export default HoldAndEarn;
