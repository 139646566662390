import { PropsWithChildren } from "react";

const DottedUnderline = ({ children }: PropsWithChildren) => {
    return (
        <span
            className="inline-flex bg-bottom bg-repeat-x pb-0.5"
            style={{
                backgroundImage: "linear-gradient(to right, white 33%, transparent 0%)",
                backgroundSize: "3px 1px",
            }}
        >
            {children}
        </span>
    );
};
export default DottedUnderline;
