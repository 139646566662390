import * as React from "react";
import { useMergeRefs, FloatingPortal } from "@floating-ui/react";
import type { Placement } from "@floating-ui/react";
import { useTooltipContext } from "@src/hooks/Tooltip/useTooltipContext";

const getArrowPlacement = (placement: Placement) => {
    const baseStyles = "absolute w-3 h-3 bg-backgroundSecondary rotate-45 transform border-border";
    switch (placement) {
        case "top":
            return {
                className: `${baseStyles} border-r border-b`,
                style: {
                    bottom: "-6px",
                    left: "50%",
                    transform: "translate(-50%, 0) rotate(45deg)",
                },
            };
        case "bottom":
            return {
                className: `${baseStyles} border-l border-t`,
                style: {
                    top: "-6px",
                    left: "50%",
                    transform: "translate(-50%, 0) rotate(45deg)",
                },
            };
        case "left":
            return {
                className: `${baseStyles} border-t border-r`,
                style: {
                    right: "-6px",
                    top: "50%",
                    transform: "translate(0, -50%) rotate(45deg)",
                },
            };
        case "right":
            return {
                className: `${baseStyles} border-b border-l`,
                style: {
                    left: "-6px",
                    top: "50%",
                    transform: "translate(0, -50%) rotate(45deg)",
                },
            };
        default:
            return {
                className: baseStyles,
                style: {
                    bottom: "-6px",
                    left: "50%",
                    transform: "translate(-50%, 0) rotate(45deg)",
                },
            };
    }
};

export const TooltipTrigger = React.forwardRef<
    HTMLElement,
    React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
    const context = useTooltipContext();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    if (asChild && React.isValidElement(children)) {
        return React.cloneElement(
            children,
            context.getReferenceProps({
                ref,
                ...props,
                ...children.props,
                "data-state": context.open ? "open" : "closed",
            })
        );
    }

    return (
        <button
            ref={ref}
            data-state={context.open ? "open" : "closed"}
            {...context.getReferenceProps(props)}
        >
            {children}
        </button>
    );
});

export const TooltipContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
    function TooltipContent({ style, className, children, ...props }, propRef) {
        const context = useTooltipContext();
        const ref = useMergeRefs([context.refs.setFloating, propRef]);
        const arrowStyles = getArrowPlacement(context.placement);

        if (!context.open) return null;

        return (
            <FloatingPortal>
                <div
                    ref={ref}
                    className={`relative z-50 ${className ?? ""}`}
                    style={{
                        ...context.floatingStyles,
                        ...style,
                    }}
                    {...context.getFloatingProps(props)}
                >
                    <div className="bg-backgroundSecondary px-3 py-2 rounded text-sm shadow-lg border border-border overflow-hidden">
                        {children}
                    </div>
                    <div
                        ref={context.arrowRef}
                        className={arrowStyles.className}
                        style={arrowStyles.style}
                    />
                </div>
            </FloatingPortal>
        );
    }
);
