import { useReadContract } from "wagmi";
import { s0VaultABI } from "../contracts/s0VaultABI";
import { config } from "../config";
import { formatUnits } from "viem";
import { UseReadContractParameters } from "wagmi";
import { QueryClient } from "@tanstack/react-query";

const TVL_REFRESH_INTERVAL = 1000 * 60;

export const tvlQueryOptions = {
    abi: s0VaultABI,
    address: config.contracts.vault.address,
    functionName: "totalAssets",
} as const satisfies UseReadContractParameters;

export const useTvl = () =>
    useReadContract({
        ...tvlQueryOptions,
        query: {
            refetchInterval: TVL_REFRESH_INTERVAL,
            select: (totalAssets) =>
                Number(formatUnits(totalAssets, config.contracts.vault.assetToken.decimals)),
        },
    });

export const invalidateTvlQuery = (queryClient: QueryClient) =>
    queryClient.invalidateQueries({ queryKey: ["readContract", tvlQueryOptions] });
