import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

interface AccordionItemProps {
    title: string;
    children: React.ReactNode;
    isOpen: boolean;
    onToggle: () => void;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
    title,
    children,
    isOpen,
    onToggle,
}) => {
    return (
        <div className="mt-4 rounded overflow-hidden text-sm">
            <button
                data-testid="accordion-item-btn"
                className="w-full flex justify-between items-center p-4 text-left hover:bg-backgroundPrimary/80 bg-backgroundPrimary/85"
                onClick={onToggle}
            >
                <span className="text-text-secondary">{title}</span>
                <ChevronDownIcon
                    className={`size-5 text-text-secondary transition-transform duration-200 ${
                        isOpen ? "rotate-180" : ""
                    }`}
                />
            </button>
            <div
                className={`overflow-hidden transition-all duration-200 ease-in-out ${
                    isOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
                }`}
            >
                <div className="p-4 bg-backgroundPrimary/85 text-text-primary border-border border-t ">
                    {children}
                </div>
            </div>
        </div>
    );
};

interface AccordionProps {
    items: {
        id: string;
        title: string;
        content: React.ReactNode;
    }[];
    allowMultiple?: boolean;
}
export const Accordion: React.FC<AccordionProps> = ({ items, allowMultiple = false }) => {
    const [openItems, setOpenItems] = useState<string[]>(["1"]);

    const handleToggle = (itemId: string) => {
        if (allowMultiple) {
            setOpenItems((prev) =>
                prev.includes(itemId) ? prev.filter((id) => id !== itemId) : [...prev, itemId]
            );
        } else {
            setOpenItems((prev) => (prev.includes(itemId) ? [] : [itemId]));
        }
    };

    return (
        <div>
            {items.map((item) => (
                <AccordionItem
                    key={item.id}
                    title={item.title}
                    isOpen={openItems.includes(item.id)}
                    onToggle={() => handleToggle(item.id)}
                >
                    {item.content}
                </AccordionItem>
            ))}
        </div>
    );
};

export default Accordion;
