export enum NodeEnvironment {
    MAINNET = "mainnet",
    TESTNET = "testnet",
    LOCAL = "local",
}

export interface AccountsData {
    accounts: AccountDto[];
    refereeAccounts: AccountSummary[]; // total balance and points earned by all referees
    rank: string | null;
}

export interface AccountDto {
    address: string;
    assetAddress: string;
    points: string;
    balance: string;
    blockNumber: string;
    lastCalculatedAt: string;
}

export type AccountSummary = Required<Pick<AccountDto, "assetAddress" | "balance" | "points">>;

export interface ReferralDto {
    address: string;
    myReferralCode: string | null;
    referrer: string | null;
    referralPct: number | null;
    hasSignedTos: boolean;
    isVip?: boolean;
}

export interface Token {
    name: string;
    symbol: string;
    decimals?: number;
    address?: string;
    icon: string;
}

export interface Referee {
    refereeAddress: string;
    eUsdeAmount: string;
    totalRewards: string;
    date: number;
}

// This is used to display points that are simulating ticking up
export interface DynamicPointsDisplayInfo {
    myPts: number;
    totalRefereePts: number;
    lastUpdated: number;
}

export interface ApiConfigDto {
    contracts: ContractsConfig;
    referralPointPct: number;
    pagination: PaginationConfig;
}

export interface ContractsConfig {
    vaultMultiplier: number;
    pendleYtMultiplier: number;
    pendleLpMultiplier: number;
    morphoApostroMultiplier: number;
    morphoSparkMultiplier: number;
    eulerMultiplier: number;
}

export interface PaginationConfig {
    referees: number;
    depositWithdrawals: number;
}
