import { createBrowserRouter, Navigate } from "react-router-dom";
import Placeholder from "../pages/Placeholder";
import PointsPage from "../pages/Points";
import Layout from "../Components/Layout";
import NotFound from "../pages/404";
import PrivacyPage from "@src/pages/Privacy";
import TermsPage from "@src/pages/Terms";

export const TERMS_ROUTE = {
    path: "/terms",
    element: <TermsPage />,
    name: "Terms",
    hidden: true,
};

export const PRIVACY_ROUTE = {
    path: "/privacy",
    element: <PrivacyPage />,
    name: "Privacy",
    hidden: true,
};

const routeChildren: {
    path: string;
    element: JSX.Element;
    name: string;
    disabled?: boolean;
    hidden?: boolean;
}[] = [
    {
        path: "/",
        element: <Navigate to="/points" />,
        name: "Home",
    },
    {
        path: "/trade",
        element: <Placeholder />,
        name: "Trade",
        disabled: true,
    },
    {
        path: "/portfolio",
        name: "Portfolio",
        element: <Placeholder />,
        disabled: true,
    },
    {
        path: "/points",
        name: "Points",
        element: <PointsPage />,
    },
    TERMS_ROUTE,
    PRIVACY_ROUTE,
    {
        name: "",
        path: "*",
        element: <NotFound />,
    },
];

export const routes = [
    {
        path: "/",
        element: <Layout />,
        children: routeChildren,
    },
];

export const router = createBrowserRouter(routes);
