import { useState, type ImgHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface FadeInImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    opacityClassName?: string;
}

const FadeInImage = ({
    className = "",
    opacityClassName = "opacity-100",
    ...props
}: FadeInImageProps) => {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <img
            {...props}
            className={twMerge(
                "opacity-0 transition-opacity",
                isLoaded && opacityClassName,
                className
            )}
            onLoad={async () => setIsLoaded(true)}
        />
    );
};

export default FadeInImage;
