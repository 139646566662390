import { CheckIcon } from "@heroicons/react/24/solid";
import { Square2StackIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

const CopyButton = ({
    textToCopy,
    text,
}: {
    textToCopy: string;
    text: string | React.ReactNode;
}) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        await navigator.clipboard.writeText(textToCopy);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <div className="relative">
            <button onClick={handleCopy} className="flex gap-2" data-testid="copy-btn">
                {text}

                {copied ? (
                    <CheckIcon className={"size-4 stroke-text-primary"} />
                ) : (
                    <Square2StackIcon className={"size-4 stroke-text-primary"} />
                )}
            </button>

            {copied && (
                <div className="absolute -top-8 left-1/2 -translate-x-1/2 px-2 py-1 text-xs text-white bg-gray-800 rounded">
                    Copied!
                </div>
            )}
        </div>
    );
};

export default CopyButton;
