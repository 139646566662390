import { Button } from "@Catalyst/button";
import CopyButton from "@Components/GeneralComponents/CopyButton";
import ReferIcon from "@src/assets/SeasonPoints/ReferIcon.svg";
import RefereesDialog from "../RefereesDialog";
import { useEffect, useState } from "react";
import { useGlobalContext } from "@src/hooks/Global/useGlobalContext";
import { useAccount } from "wagmi";
import { api } from "@src/util/api";
import axios from "axios";
import CustomConnectButton from "@Components/GeneralComponents/CustomConnectButton";
import { formatNumberTwoDecimalsFromString } from "@src/util/util";

const ReferRow = () => {
    const [open, setOpen] = useState(false);
    const { referral, setReferral, eUsdebalance, dynamicPointsDisplayInfo, apiConfig } =
        useGlobalContext();
    const { address, isConnected } = useAccount();
    const rewardPct = referral?.referralPct || apiConfig.referralPointPct;

    // useEffect to fetch referral code if they have eUSD but no referral code yet (i.e. they've just deposited)
    useEffect(() => {
        async function getReferralDbRecord() {
            try {
                const res = await api.get(`/referral/${address}`);
                const referralResponse = res.data;
                setReferral(referralResponse);
            } catch (error) {
                console.error(`Error fetching referral code: ${error}`);
                if (axios.isAxiosError(error) && error.response?.status === 404) {
                    setReferral(null);
                }
            }
        }
        if (
            address &&
            !referral?.myReferralCode &&
            !isNaN(Number(eUsdebalance)) &&
            Number(eUsdebalance) > 0
        ) {
            getReferralDbRecord();
        }
    }, [referral?.myReferralCode, eUsdebalance, address]);

    const headers = [
        { name: "Refer", className: "w-5/12" },
        { name: "Points", className: "w-1/6 text-right" },
        { name: "Link", className: "w-5/12 text-right" },
    ];

    const renderHeader = ({ name, className }: { name: string; className?: string }) => {
        const headerClassName = `${className || ""} text-[10px] font-secondary text-text-secondary`;

        return (
            <div key={name} className={headerClassName}>
                {name}
            </div>
        );
    };

    const renderLinkCell = () => {
        const url = new URL(window.location.href);
        const baseUrl = url.origin + url.pathname;

        const textNode = (
            <p className="text-text-secondary text-right">
                {baseUrl}?ref=<span className="text-text-primary">{referral?.myReferralCode}</span>
            </p>
        );
        const textToCopy = `${baseUrl}?ref=${referral?.myReferralCode}`;
        if (!isConnected) {
            return <CustomConnectButton classname="w-fit text-xs py-1" />;
        }
        if (!referral?.myReferralCode) {
            return (
                <div className="text-xs font-medium text-text-secondary">
                    Deposit to generate referral link
                </div>
            );
        }
        return <CopyButton textToCopy={textToCopy} text={textNode} />;
    };

    const renderDepositRow = () => {
        const points = dynamicPointsDisplayInfo.totalRefereePts * (rewardPct / 100);
        return (
            <>
                {/* Desktop */}
                <div className="hidden md:flex items-center justify-between py-4 pl-4 pr-3">
                    <div className="flex items-center space-x-3 w-5/12">
                        <img src={ReferIcon} width={32} height={32} draggable={false} />
                        <div>
                            <div>Share your referral link</div>
                            <div className="text-text-secondary">
                                {rewardPct}% of referees' total rewards
                            </div>
                        </div>
                    </div>
                    <div
                        className={`w-1/6 text-right ${points === 0 ? "text-text-secondary" : ""}`}
                    >
                        {formatNumberTwoDecimalsFromString(points.toString())}
                    </div>
                    <div className={"w-5/12 flex justify-end"}>{renderLinkCell()}</div>
                </div>

                {/* Mobile */}
                <div className="flex-col md:hidden px-2">
                    <div className="flex items-center space-x-3 mb-3">
                        <img src={ReferIcon} width={32} height={32} draggable={false} />
                        <div>
                            <div>Share your referral link</div>
                            <div className="text-text-secondary">
                                {rewardPct}% of referees' total rewards
                            </div>
                        </div>
                    </div>
                    {/* header */}
                    <div className="flex px-4 py-1">
                        <div className="text-[10px] text-text-secondary w-1/3">Points</div>
                        <div className="text-[10px] text-text-secondary w-2/3 text-right">Link</div>
                    </div>
                    {/* values */}
                    <div className="flex pl-4 pr-3 py-4">
                        <div
                            className={`text-xs w-1/4 ${points === 0 ? "text-text-secondary" : ""}`}
                        >
                            {formatNumberTwoDecimalsFromString(points.toString())}
                        </div>
                        <div className="w-3/4 flex justify-end">{renderLinkCell()}</div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="text-xs">
            {/* Header - Only visible on desktop */}{" "}
            <div className="hidden md:flex items-center justify-between py-1 px-4 bg-backgroundSecondary">
                {headers.map((h) => renderHeader(h))}
            </div>
            {renderDepositRow()}
            {isConnected && (
                <Button
                    data-testid="see-all-referees-btn"
                    outline
                    className="float-right text-xs sm:text-xs mt-2 md:mt-0 py-1 px-2 sm:px-2 sm:py-1"
                    onClick={() => setOpen(true)}
                >
                    See all referees
                </Button>
            )}
            <RefereesDialog open={open} onClose={() => setOpen(false)} />
        </div>
    );
};

export default ReferRow;
