import { mainnet, sepolia } from "viem/chains";
import { getSelectedChainId } from "../viemConfig";

export const isStringValidNumberInput = (str: string): boolean => {
    // differs from isNumeric in that it validates number input strings during typing/editing (e.g. allows "42.")
    const numberPattern = /^(0|[1-9]\d*)?(\.|(\.\d*))?$/;
    return numberPattern.test(str);
};

// Be sure to note this is for display purposes only - not always a number
export const formatNumberTwoDecimalsFromString = (value: string): string => {
    if (isNaN(parseFloat(value))) {
        value = "0";
    }
    if (Number(value) < 0.01 && Number(value) > 0) {
        return "< 0.01";
    }
    const num = Math.floor(parseFloat(value) * 100) / 100; // Floor to 2 decimal places
    return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(num);
};

export function nowInNanoSeconds() {
    const dateNow = Date.now();
    const perfNow = performance.now(); // ms with decimal for high resolution timing

    // Convert the current date to nanoseconds
    const dateInNanoSeconds = BigInt(dateNow) * 1000000n;

    // Convert performance.now() to the nearest microsecond and then to nanoseconds
    const perfInNanoSeconds = BigInt(Math.floor(perfNow * 1000)) * 1000n;

    // Combine both to approximate a higher resolution nanosecond count
    return (dateInNanoSeconds + perfInNanoSeconds).toString();
}

export function formatTimestamp(timestamp: number): string {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("en", { month: "short" });
    const year = date.getFullYear();

    return `${hours}:${minutes} · ${day} ${month} ${year}`;
}

export const shortenAddress = (str: string) => {
    return `${str.slice(0, 6)}...${str.slice(str.length - 4)}`;
};

export const truncate = (str: string | undefined, n: number) => {
    if (!str) return "";
    if (str.length <= n) return str;

    // Find the last space before n characters
    const lastSpace = str.lastIndexOf(" ", n);

    // If no space found, fall back to character truncation
    if (lastSpace === -1) return str.slice(0, n) + "...";

    // Return string up to last space with ellipsis
    return str.slice(0, lastSpace) + "...";
};

export function formatNumberWithCommas(input: string): string {
    // Return empty string if input is empty/null/undefined
    if (!input) return "";

    // Handle just a decimal point
    if (input === ".") return ".";

    // Split the number into integer and decimal parts
    const [integerPart = "", decimalPart = ""] = input.split(".");

    // Add commas to the integer part if it's not empty
    const formattedInteger = integerPart ? integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";

    // If input ends with a decimal point or has decimal numbers, add them back
    if (input.includes(".")) {
        return `${formattedInteger}.${decimalPart}`;
    }

    return formattedInteger;
}

export const getEtherscanLink = (txHash: string | undefined) => {
    if (!txHash) return null;
    const chainId = getSelectedChainId();

    // Return Sepolia explorer for testnet
    if (chainId === sepolia.id) {
        return `https://sepolia.etherscan.io/tx/${txHash}`;
    }

    // Return mainnet explorer for mainnet
    if (chainId === mainnet.id) {
        return `https://etherscan.io/tx/${txHash}`;
    }

    // Return null for local development (anvil)
    return null;
};

export async function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

type FormattedLargeNumber = {
    value: number;
    suffix: string;
};

export function formatLargeNumber(num: number): FormattedLargeNumber {
    const suffixes = [
        { value: 1e12, symbol: "T" },
        { value: 1e9, symbol: "B" },
        { value: 1e6, symbol: "M" },
        { value: 1e3, symbol: "K" },
    ];

    const suffix = suffixes.find(({ value }) => num >= value);

    if (suffix) {
        const scaledValue = num / suffix.value;

        return {
            value: Number(scaledValue.toFixed(1)),
            suffix: suffix.symbol,
        };
    }

    return {
        value: num,
        suffix: "",
    };
}

export const indexToLetter = (index: number): string => {
    return String.fromCharCode(65 + index); // 65 is ASCII for 'A'
};
