import { useEffect, useState } from "react";
import { Button } from "@Catalyst/button";
import { erc20Abi, parseUnits, zeroAddress } from "viem";
import InfoCircleBlue from "@src/assets/information-circle-blue.svg";
import { ArrowPathIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { useAccount, useWaitForTransactionReceipt } from "wagmi";
import { s0VaultABI } from "@src/contracts/s0VaultABI";
import { useWriteContract, useReadContract } from "wagmi";
import DepositInput from "./DepositInput";
import {
    formatLargeNumber,
    formatNumberTwoDecimalsFromString,
    getEtherscanLink,
} from "@src/util/util";
import { ConnectKitButton, useModal } from "connectkit";
import { useGlobalContext } from "@src/hooks/Global/useGlobalContext";
import { config } from "@src/config";
import DepositHistory from "../DepositHistory";
import toast from "react-hot-toast";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/16/solid";
import ConfirmWithdrawModal from "./ConfirmWithdrawModal";
import { isUserRejectedError } from "@src/util/error";
import { twMerge } from "tailwind-merge";
import { useQueryClient } from "@tanstack/react-query";
import { invalidateTvlQuery, useTvl } from "../../../hooks/useTvl";

enum TAB_OPTIONS {
    "Deposit" = "Pre-Deposit",
    "Withdraw" = "Withdraw",
    "History" = "History",
}

function DepositForm() {
    // state vars
    const [amountInput, setAmountInput] = useState<string>("");
    const [activeTab, setActiveTab] = useState<TAB_OPTIONS>(TAB_OPTIONS.Deposit);
    const [txnStep, setTxnStep] = useState(0);
    const [pendingTxn, setPendingTxn] = useState(false);
    const [openWithdrawModal, setOpenWithdrawModal] = useState(false);

    // hooks
    const queryClient = useQueryClient();
    const {
        balance,
        getBalance,
        geteUsdeBalance,
        withdrawalEnabled,
        eUsdebalance,
        refetchAccountData,
    } = useGlobalContext();
    const { data: tvl } = useTvl();
    const { address } = useAccount();
    const { openSwitchNetworks } = useModal();

    const VAULT_ADDRESS = config.contracts.vault.address;
    const VAULT_ASSET_TOKEN_ADDRESS = config.contracts.vault.assetToken.address;
    const VAULT_ASSET_TOKEN_DECIMALS = config.contracts.vault.assetToken.decimals;
    const DEPOSIT_CAP = config.contracts.vault.depositCap;

    const resetAfterError = () => {
        setTxnStep(0);
        setPendingTxn(false);
        refetchAllowance();
    };

    // Check allowance
    const {
        data: allowance,
        error: allowanceError,
        refetch: refetchAllowance,
    } = useReadContract({
        address: VAULT_ASSET_TOKEN_ADDRESS,
        abi: erc20Abi,
        functionName: "allowance",
        args: [address || zeroAddress, VAULT_ADDRESS],
        query: { enabled: Boolean(address) },
    });

    useEffect(() => {
        if (allowanceError) {
            console.error("Error getting allowance", allowanceError);
            toast.error(`Error getting allowance: ${allowanceError.message}`);
        }
    }, [allowanceError]);

    const amountWei: bigint = amountInput
        ? parseUnits(amountInput, VAULT_ASSET_TOKEN_DECIMALS)
        : 0n;

    const allowanceBigInt = allowance as bigint | undefined | null;
    const allowanceFound = allowanceBigInt !== undefined && allowanceBigInt !== null;
    const requireApproval = allowanceFound && allowanceBigInt < amountWei;

    const errorMessage = (() => {
        if (pendingTxn) return undefined;
        if (!amountInput) return undefined;
        if (activeTab === TAB_OPTIONS.Deposit) {
            const newTvl = tvl && tvl + Number(amountInput);
            const depositCapReached = newTvl && newTvl > DEPOSIT_CAP;
            if (depositCapReached && DEPOSIT_CAP) {
                const formattedCap = formatLargeNumber(DEPOSIT_CAP);
                return `Deposit would exceed deposit limit ($${formattedCap.value}${formattedCap.suffix}).`;
            }
            const balanceExceeded = Number(balance) < Number(amountInput);
            if (balanceExceeded) {
                return "Balance exceeded. Please enter a lower amount.";
            }
        } else if (activeTab === TAB_OPTIONS.Withdraw) {
            const balanceExceeded = Number(eUsdebalance) < Number(amountInput);
            if (balanceExceeded) {
                return "Balance exceeded. Please enter a lower amount.";
            }
        }
        return undefined;
    })();
    // Write functions
    // approve
    const {
        writeContract: writeApprove,
        error: approveError,
        data: approveHash,
        isSuccess: isApproveSuccess,
    } = useWriteContract();
    const { isSuccess: approveReceiptSuccess } = useWaitForTransactionReceipt({
        hash: approveHash,
    });
    useEffect(() => {
        if (approveError && !isUserRejectedError(approveError)) {
            console.error("Error approving transaction", approveError);
            toast.error(`Error approving transaction: ${approveError.message}`);
        }
        resetAfterError();
    }, [approveError]);

    useEffect(() => {
        const handleApprovalSuccess = async () => {
            if (!address) {
                resetAfterError();
                toast.error("Please connect");
                return;
            }
            writeContract({
                address: VAULT_ADDRESS,
                abi: s0VaultABI,
                functionName: "deposit",
                args: [amountWei, address],
            });

            setTxnStep(2);
        };
        if (approveReceiptSuccess && txnStep === 1) {
            handleApprovalSuccess();
        }
    }, [approveReceiptSuccess, txnStep]);

    // Deposit/Withdraw
    const {
        writeContract,
        error: writeError,
        data,
        isSuccess: isDepositWithdrawSuccess,
    } = useWriteContract();
    const {
        isSuccess: depositWithdrawReceiptSuccess,
        data: receipt,
        error: receiptError,
    } = useWaitForTransactionReceipt({ hash: data });

    useEffect(() => {
        const handleSuccess = async () => {
            await Promise.all([getBalance(), geteUsdeBalance(), refetchAccountData()]);
            invalidateTvlQuery(queryClient);

            const link = getEtherscanLink(receipt?.transactionHash);
            toast.custom(
                <span className="flex justify-between w-[390px] bg-toastBgSuccess p-2 border border-white/10 rounded">
                    <div className="flex items-center gap-2">
                        <CheckCircleIcon className={`size-5 shrink-0 text-success`} />
                        Successfully{" "}
                        {activeTab === TAB_OPTIONS.Withdraw ? "withdrawn" : "deposited"}!
                    </div>
                    {link && (
                        <a
                            className="flex items-center gap-1 text-success"
                            target="_blank"
                            href={link}
                        >
                            Etherscan <ArrowTopRightOnSquareIcon className="size-5" />
                        </a>
                    )}
                </span>
            );
            setAmountInput("");
            setTxnStep(0);
            setPendingTxn(false);
            await refetchAllowance();
        };

        if (depositWithdrawReceiptSuccess && txnStep === 2) {
            handleSuccess();
        }
    }, [depositWithdrawReceiptSuccess, txnStep]);

    useEffect(() => {
        if (!isUserRejectedError(receiptError || writeError) && (receiptError || writeError)) {
            console.error(`Error: ${receiptError?.message || writeError?.message}`);
            toast.error(`Error: ${receiptError?.message || writeError?.message}`);
            resetAfterError();
        } else if (writeError) {
            resetAfterError();
        }
    }, [receiptError, writeError]);

    const handleDeposit = async () => {
        setPendingTxn(true);
        if (!address) {
            resetAfterError();
            toast.error("Please connect");
            return;
        }
        // Check if approval is needed
        if (requireApproval) {
            writeApprove({
                address: VAULT_ASSET_TOKEN_ADDRESS,
                abi: erc20Abi,
                functionName: "approve",
                args: [VAULT_ADDRESS, amountWei],
            });
            setTxnStep(1);
        } else {
            writeContract({
                address: VAULT_ADDRESS,
                abi: s0VaultABI,
                functionName: "deposit",
                args: [amountWei, address],
            });
            setTxnStep(2);
        }
    };

    const handleWithdraw = async () => {
        setOpenWithdrawModal(false);
        setPendingTxn(true);
        if (!address) {
            resetAfterError();
            toast.error("Please connect");
            return;
        }
        writeContract({
            address: VAULT_ADDRESS,
            abi: s0VaultABI,
            functionName: "withdraw",
            args: [amountWei, address, address],
        });
        setTxnStep(2);
    };

    const handleSubmit = async () => {
        if (activeTab === TAB_OPTIONS.Deposit) {
            handleDeposit();
        } else if (activeTab === TAB_OPTIONS.Withdraw) {
            setOpenWithdrawModal(true);
        }
    };

    const getButtonText = () => {
        if (txnStep === 1 && isApproveSuccess && !approveReceiptSuccess) {
            return "Approving...";
        }
        if (txnStep === 2 && isDepositWithdrawSuccess && !depositWithdrawReceiptSuccess) {
            return `Processing ${activeTab === TAB_OPTIONS.Withdraw ? "withdrawal" : "deposit"}...`;
        }

        if (activeTab === TAB_OPTIONS.Deposit) {
            if (tvl && DEPOSIT_CAP && tvl > DEPOSIT_CAP) {
                return "Deposit limit exceeded";
            }
            return !requireApproval || txnStep === 2
                ? "Pre-Deposit into Ethereal"
                : "Approve Pre-Deposit into Ethereal";
        } else {
            return "Withdraw";
        }
    };
    const getButtonTestId = () => {
        switch (activeTab) {
            case TAB_OPTIONS.Deposit:
                return requireApproval
                    ? "deposit-form-approve-and-deposit-btn"
                    : "deposit-form-deposit-btn";
            case TAB_OPTIONS.Withdraw:
                return "deposit-form-withdraw-btn";
            default:
                return "";
        }
    };

    const renderDepositDetail = (label: string, value: string, className?: string) => {
        return (
            <div className={`flex flex-row justify-between ${className}`}>
                <div className="text-sm">{label}</div>
                <div className="text-sm">{value}</div>
            </div>
        );
    };

    const renderTabs = () => {
        const defaultClassName = `flex-1 py-3 px-4 sm:py-3 sm:px-4 font-medium border-x-0 border-t-0 text-sm
            !bg-transparent
            hover:!bg-transparent 
            hover:!text-white
            active:!bg-transparent
            focus:!bg-transparent
            focus-visible:!bg-transparent
            focus-visible:outline-none`;
        return (
            <div className="w-full flex mb-2">
                {Object.keys(TAB_OPTIONS).map((key) => {
                    if (key === TAB_OPTIONS.Withdraw && !withdrawalEnabled) {
                        return null;
                    }
                    const testId = (() => {
                        switch (key) {
                            case TAB_OPTIONS.Deposit:
                                return "deposit-form-tab-deposit";
                            case TAB_OPTIONS.Withdraw:
                                return "deposit-form-tab-withdraw";
                            case TAB_OPTIONS.History:
                                return "deposit-form-tab-history";
                            default:
                                return "";
                        }
                    })();

                    return (
                        <Button
                            data-testid={testId}
                            plain
                            key={key}
                            disabled={pendingTxn}
                            onClick={() =>
                                setActiveTab(TAB_OPTIONS[key as keyof typeof TAB_OPTIONS])
                            }
                            className={twMerge(
                                defaultClassName,
                                activeTab === TAB_OPTIONS[key as keyof typeof TAB_OPTIONS]
                                    ? "border-b-primary border-b-2 rounded-none"
                                    : "dark:text-text-secondary border-b border-b-border rounded-none"
                            )}
                        >
                            {TAB_OPTIONS[key as keyof typeof TAB_OPTIONS]}
                        </Button>
                    );
                })}
            </div>
        );
    };

    const renderActionButton = () => {
        return (
            <ConnectKitButton.Custom>
                {({ isConnected, show, chain }) => {
                    if (!isConnected) {
                        return (
                            <button
                                data-testid="connect-button"
                                onClick={show}
                                className="w-full bg-primary p-3  hover:brightness-110 rounded text-backgroundInversePrimary text-lg font-primary"
                            >
                                Connect
                            </button>
                        );
                    }
                    if (!chain) {
                        return (
                            <button
                                data-testid="switch-networks-button"
                                onClick={() => openSwitchNetworks()}
                                className="w-full bg-background-red rounded p-3 hover:brightness-110 text-backgroundInversePrimary text-lg font-primary"
                            >
                                Wrong network
                            </button>
                        );
                    }

                    return (
                        <Button
                            data-testid={getButtonTestId()}
                            color={
                                activeTab === TAB_OPTIONS.Deposit
                                    ? tvl && DEPOSIT_CAP && tvl > DEPOSIT_CAP
                                        ? "red"
                                        : "primary"
                                    : "red"
                            }
                            className="!text-lg !p-3 w-full"
                            onClick={handleSubmit}
                            disabled={
                                pendingTxn ||
                                amountInput === "" ||
                                isNaN(Number(amountInput)) ||
                                Number(amountInput) <= 0 ||
                                // Disable button if there's an error message
                                errorMessage !== undefined ||
                                // Disable button until tvl is fetching
                                !tvl ||
                                // Disable button if deposit caps are hit AND we're on the deposit tab
                                Boolean(
                                    activeTab === TAB_OPTIONS.Deposit &&
                                        tvl &&
                                        DEPOSIT_CAP &&
                                        tvl > DEPOSIT_CAP
                                )
                            }
                        >
                            {pendingTxn && (
                                <ArrowPathIcon className="!w-5 !h-5 mr-2 animate-spin " />
                            )}
                            {getButtonText()}
                        </Button>
                    );
                }}
            </ConnectKitButton.Custom>
        );
    };

    const renderTabBody = () => {
        if (activeTab === TAB_OPTIONS.History) {
            return <DepositHistory />;
        }

        return (
            <div className="p-4">
                {/* Input Amount */}
                <div>Amount</div>
                <DepositInput
                    amountInput={amountInput}
                    setAmountInput={setAmountInput}
                    errorMessage={errorMessage}
                    activeTab={activeTab}
                    pendingTxn={pendingTxn}
                />

                {/* Deposit details */}
                <div className="mt-2 flex flex-col gap-2 text-text-secondary">
                    {renderDepositDetail(
                        "Value",
                        `${formatNumberTwoDecimalsFromString(amountInput)} ${activeTab === TAB_OPTIONS.Deposit ? `eUSDe` : "USDe"}`
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="flex flex-col w-full justify-between min-h-[310px]">
            <div className="flex flex-col flex-1">
                {renderTabs()}
                {renderTabBody()}
            </div>
            {activeTab === TAB_OPTIONS.Withdraw && (
                // hidden until integrations are live
                <div className="p-2 mx-4 text-text-secondary text-xs gap-x-2 border border-border rounded hidden">
                    <img src={InfoCircleBlue} alt="Info Icon" className="size-4" />
                    <div>
                        If your eUSDe is deposited in other venues, withdraw it to ensure it appears
                        in your balance.
                    </div>
                </div>
            )}
            {activeTab !== TAB_OPTIONS.History && <div className="p-4">{renderActionButton()}</div>}
            <ConfirmWithdrawModal
                open={openWithdrawModal}
                handleClose={() => {
                    setOpenWithdrawModal(false);
                    setPendingTxn(false);
                }}
                handleAccept={handleWithdraw}
            />
        </div>
    );
}

export default DepositForm;
