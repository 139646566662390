import {
    formatNumberTwoDecimalsFromString,
    formatNumberWithCommas,
    isStringValidNumberInput,
} from "@src/util/util";
import clsx from "clsx";
import { Dispatch, SetStateAction } from "react";
import USDe from "@src/assets/tokenIcons/USDe.svg";
import { useGlobalContext } from "@src/hooks/Global/useGlobalContext";
import { useAccount } from "wagmi";

enum TAB_OPTIONS {
    "Deposit" = "Pre-Deposit",
    "Withdraw" = "Withdraw",
    "History" = "History",
}
interface Props {
    amountInput: string;
    setAmountInput: Dispatch<SetStateAction<string>>;
    errorMessage: string | undefined;
    activeTab: TAB_OPTIONS;
    pendingTxn: boolean;
}
const DepositInput: React.FC<Props> = ({
    amountInput,
    setAmountInput,
    errorMessage,
    activeTab,
    pendingTxn,
}) => {
    const { balance, eUsdebalance } = useGlobalContext();
    const { isConnected } = useAccount();

    /**
     * Handles updates to the user's input for the pre-Deposit amount.
     * Only updates the state if the input is a valid number.
     *
     * @param amount The user's current input for the pre-Deposit amount
     */
    const handleUpdateDeposityAmount = (amount: string) => {
        // First check if it's a valid number input
        if (!isStringValidNumberInput(amount)) {
            return;
        }

        // Check for decimal places
        const parts = amount.split(".");
        if (parts[1] && parts[1].length > 18) {
            // Truncate to 18 decimal places
            const truncated = `${parts[0]}.${parts[1].slice(0, 18)}`;
            setAmountInput(truncated);
            return;
        }

        setAmountInput(amount);
    };

    const handleMax = () => {
        if (activeTab === TAB_OPTIONS.Withdraw) {
            setAmountInput(eUsdebalance.toString());
            return;
        }
        setAmountInput(balance);
    };

    const disableMax = () => {
        if (!isConnected || pendingTxn) return true;
        if (activeTab === TAB_OPTIONS.Withdraw) {
            return eUsdebalance === "0" || !eUsdebalance;
        }
        return balance === "0" || !balance;
    };

    const replaceComma = (value: string) => {
        return value.replace(/,/g, "");
    };

    const showBalance = () => {
        if (!isConnected) return "-";
        if (activeTab === TAB_OPTIONS.Deposit) {
            return formatNumberTwoDecimalsFromString(balance);
        }
        return formatNumberTwoDecimalsFromString(eUsdebalance);
    };

    return (
        <div>
            <div className="w-full mt-2">
                <div
                    className={clsx([
                        // basic layout
                        "relative border rounded",
                        // Border
                        "border border-border data-[hover]:border-zinc-950/20 dark:border-white/10 dark:data-[hover]:border-white/20",
                        // Background color
                        "bg-transparent dark:bg-backgroundSecondary",
                    ])}
                >
                    {/* Main input */}
                    <input
                        value={formatNumberWithCommas(amountInput)}
                        placeholder="Enter amount"
                        disabled={!isConnected || pendingTxn}
                        onChange={(e) => handleUpdateDeposityAmount(replaceComma(e.target.value))}
                        className="w-full bg-transparent px-3 pt-3 pb-9 text-white text-lg !rounded pr-28 outline-primary"
                    />

                    {/* Currency and info icon */}
                    <div className="absolute right-3 top-2 flex items-center space-x-2 border border-neutral-200/10 rounded px-2 py-1">
                        <img
                            src={USDe}
                            alt="USDe logo"
                            className="size-5"
                            width={16}
                            draggable={false}
                        />
                        <span className="text-text-primary text-sm">
                            {activeTab === TAB_OPTIONS.Withdraw ? "eUSDe" : "USDe"}
                        </span>
                    </div>

                    {/* Secondary text inside input */}
                    <div className="absolute bottom-3 left-3 text-xs text-gray-500">
                        {formatNumberTwoDecimalsFromString(amountInput)}
                    </div>

                    {/* Balance text inside input */}
                    <div className="absolute bottom-3 right-3 text-xs text-gray-500">
                        Balance: {showBalance()}{" "}
                        <button
                            data-testid={
                                TAB_OPTIONS.Deposit == activeTab
                                    ? "deposit-max-btn"
                                    : "withdraw-max-btn"
                            }
                            className="text-primary"
                            onClick={handleMax}
                            disabled={disableMax()}
                        >
                            Max
                        </button>
                    </div>
                </div>
            </div>

            {errorMessage && <div className="text-red-500 text-xs mt-2">{errorMessage}</div>}
        </div>
    );
};

export default DepositInput;
