import { SparklesIcon } from "@heroicons/react/24/solid";
import { config } from "@src/config";

const PriorityUserAlert = () => {
    return (
        <div className="w-full flex flex-col md:flex-row gap-2 md:gap-0 items-center bg-backgroundSecondary p-[14px] rounded shadow-[0_0_69px_theme(colors.glow)]">
            <div className="mr-0 md:mr-4">
                <SparklesIcon className="size-6 md:size-8 fill-primary" />
            </div>
            <div className="text-center md:text-left">
                <div>Ethereal private testnet</div>
                <div className="text-text-secondary text-sm mt-2 md:mt-0">
                    You’ve made the cut! Our private testnet shortlist is exclusive, and you’re on
                    it. Participation will unfold in stages—complete the form to join the phased
                    rollout.
                </div>
            </div>
            <div className="min-w-fit ml-0 md:ml-12">
                <a
                    role="link"
                    href={config.priorityUserUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-primary p-3 hover:brightness-110 rounded text-backgroundInversePrimary text-xs font-medium py-1 px-2"
                >
                    Register here
                </a>
            </div>
        </div>
    );
};

export default PriorityUserAlert;
