// import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
// import Online from "@src/assets/onlineIndicator.svg";
import Discord from "@src/assets/SocialLogos/Discord.svg";
import Github from "@src/assets/SocialLogos/Github.svg";
import X from "@src/assets/SocialLogos/X.svg";
import Mirror from "@src/assets/SocialLogos/Mirror.svg";
import { PRIVACY_ROUTE, TERMS_ROUTE } from "@src/routes";
import { DOCS_LINK, SOCIAL_LINKS } from "@src/util/constants";
import { Link } from "react-router-dom";

function Footer() {
    const renderOperational = () => {
        return (
            <div className="flex items-center">
                {/* <img src={Online} alt="" className="h-3 w-3 mr-2" draggable={false} />
                <span className="text-xs text-text-secondary">Operational</span>
                <ArrowTopRightOnSquareIcon className="ml-2 size-3 fill-text-secondary" /> */}
            </div>
        );
    };
    return (
        <footer className="w-full bg-backgroundPrimary/85">
            <div className=" w-full flex m-auto p-[10px] items-center justify-end max-w-5xl ">
                {renderOperational()}
                <div className="flex gap-4 justify-center">
                    <a
                        href={DOCS_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xs text-text-secondary hover:brightness-150"
                    >
                        Docs
                    </a>
                    <a
                        href={SOCIAL_LINKS.DISCORD}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xs text-text-secondary hover:brightness-150"
                    >
                        Support
                    </a>
                    <Link
                        to={TERMS_ROUTE.path}
                        className="text-xs text-text-secondary hover:brightness-150"
                    >
                        {TERMS_ROUTE.name}
                    </Link>
                    <Link
                        to={PRIVACY_ROUTE.path}
                        className="text-xs text-text-secondary hover:brightness-150"
                    >
                        {PRIVACY_ROUTE.name}
                    </Link>
                    <img
                        src={Discord}
                        alt="Discord"
                        draggable={false}
                        className="cursor-pointer size-4 hover:brightness-150"
                        onClick={() =>
                            window.open(SOCIAL_LINKS.DISCORD, "_blank", "noopener,noreferrer")
                        }
                    />
                    <img
                        src={X}
                        draggable={false}
                        alt="Twitter"
                        className="cursor-pointer size-4 hover:brightness-150"
                        onClick={() =>
                            window.open(SOCIAL_LINKS.TWITTER, "_blank", "noopener,noreferrer")
                        }
                    />
                    <img
                        src={Github}
                        alt="Github"
                        draggable={false}
                        className="cursor-pointer size-4 hover:brightness-150"
                        onClick={() =>
                            window.open(SOCIAL_LINKS.GITHUB, "_blank", "noopener,noreferrer")
                        }
                    />
                    <img
                        src={Mirror}
                        alt="Mirror"
                        draggable={false}
                        className="cursor-pointer size-4 hover:brightness-150"
                        onClick={() =>
                            window.open(SOCIAL_LINKS.MIRROR, "_blank", "noopener,noreferrer")
                        }
                    />
                </div>
            </div>
        </footer>
    );
}
export default Footer;
