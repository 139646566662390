import React, { ReactNode } from "react";
import EtherealBlue from "@src/assets/tokenIcons/EtherealBlue.svg";
import Ethena from "@src/assets/tokenIcons/Ethena.svg";
import Pendle from "@src/assets/tokenIcons/Pendle.svg";
import Morpho from "@src/assets/tokenIcons/Morpho.svg";
import Euler from "@src/assets/tokenIcons/Euler.svg";

// import Curve from "@src/assets/tokenIcons/Curve.svg";
import { ArrowUpRightIcon } from "@heroicons/react/16/solid";
import { useGlobalContext } from "@src/hooks/Global/useGlobalContext";
import { formatNumberTwoDecimalsFromString } from "@src/util/util";
import { TooltipContent, TooltipProvider, TooltipTrigger } from "../../GeneralComponents/Tooltip";
import DottedUnderline from "../../GeneralComponents/DottedUnderline";
import { getAccountPointsByAssetAddress } from "@src/util/tokenUtil";
import { config } from "@src/config";
import {
    EULER_LINK_DEPOSIT,
    MORPHO_APOSTRO_LINK_EUSDE,
    PENDLE_DEPOSIT_LINK_LP,
    PENDLE_DEPOSIT_LINK_YT,
} from "@src/util/constants";

interface DepositRowProps {
    id: string;
    icon: string;
    title: string;
    canDeposit: boolean;
    bonus: ReactNode;
    balance: string;
    totalPoints: string;
    depositUrl?: string;
    description?: string;
    buttonText?: string;
}

const AssetTable: React.FC = () => {
    const { referral, eUsdebalance, accounts, apiConfig, lpPendleBalance, ytPendleBalance } =
        useGlobalContext();

    const getFormattedMultplier = (multiplier: number) => {
        const baseMultiplier = apiConfig.contracts.vaultMultiplier;
        return `${(multiplier / baseMultiplier) * 100 - 100}`;
    };

    const bonusJsx = (
        type?: "pendle-yt" | "pendle-lp" | "morpho" | "euler",
        multiplier?: string,
        ethenaMultiplier = "30"
    ) => (
        <div>
            <span className="flex items-center justify-end">
                <span
                    className={`${multiplier && Number(multiplier) > 0 ? "flex items-center gap-1 mr-1" : "hidden"}`}
                >
                    <TooltipProvider>
                        <TooltipTrigger>
                            <DottedUnderline>{multiplier}%</DottedUnderline>
                        </TooltipTrigger>
                        <TooltipContent>
                            <span className="text-xs break-normal">
                                Get {multiplier}% more Ethereal Points on your deposit with this
                                supported integration. {/* TODO remove once reindexed */}
                                {type === "pendle-lp"
                                    ? "LP points will be updated to reflect this soon."
                                    : ""}
                            </span>
                        </TooltipContent>
                    </TooltipProvider>
                    <img
                        src={EtherealBlue}
                        alt="Ethereal icon"
                        className="size-[14px] ml-px"
                        draggable={false}
                    />
                    +
                </span>
                <div className="flex items-center">
                    <TooltipProvider>
                        <TooltipTrigger>
                            <DottedUnderline>{ethenaMultiplier}x</DottedUnderline>
                        </TooltipTrigger>
                        <TooltipContent>
                            <span className="text-xs break-normal">
                                {type
                                    ? `In addition to your Ethereal rewards, receive the maximum Ethena multiplier (${ethenaMultiplier}x) on your deposit—automatically.`
                                    : `One deposit, two rewards. Receive Ethereal points plus the maximum Ethena multiplier (${ethenaMultiplier}x) on your deposit—automatically.`}
                            </span>
                        </TooltipContent>
                    </TooltipProvider>
                    <img
                        src={Ethena}
                        alt="Ethena icon"
                        className="size-[14px] ml-px"
                        draggable={false}
                    />
                </div>
            </span>
        </div>
    );

    const earnOptions: DepositRowProps[] = [
        {
            id: "ethereal",
            title: "Ethereal",
            bonus: bonusJsx(),
            balance: formatNumberTwoDecimalsFromString(eUsdebalance),
            totalPoints: getAccountPointsByAssetAddress(accounts, config.contracts.vault.address),
            canDeposit: false,
            icon: EtherealBlue,
        },
        {
            id: "pendleLp",
            title: "Pendle LP",
            bonus: bonusJsx(
                "pendle-lp",
                getFormattedMultplier(apiConfig.contracts.pendleLpMultiplier),
                "50"
            ),
            balance: formatNumberTwoDecimalsFromString(lpPendleBalance),
            totalPoints: getAccountPointsByAssetAddress(
                accounts,
                config.contracts.pendle.lpAddress
            ),
            canDeposit: true,
            icon: Pendle,
            depositUrl: PENDLE_DEPOSIT_LINK_LP,
        },
        {
            id: "pendleYt",
            title: "Pendle YT",
            bonus: bonusJsx(
                "pendle-yt",
                getFormattedMultplier(apiConfig.contracts.pendleYtMultiplier),
                "50"
            ),
            balance: formatNumberTwoDecimalsFromString(ytPendleBalance),
            totalPoints: getAccountPointsByAssetAddress(
                accounts,
                config.contracts.pendle.ytAddress
            ),
            canDeposit: true,
            icon: Pendle,
            depositUrl: PENDLE_DEPOSIT_LINK_YT,
        },
        {
            id: "morphoApostroEusdE",
            title: "Morpho Apostro",
            bonus: bonusJsx(
                "morpho",
                getFormattedMultplier(apiConfig.contracts.morphoApostroMultiplier)
            ),
            balance: "SOON",
            totalPoints: "SOON",
            canDeposit: true,
            icon: Morpho,
            depositUrl: MORPHO_APOSTRO_LINK_EUSDE,
            description: "Deposit eUSDe into Morpho",
        },
        {
            id: "morphoSparkEusde",
            title: "Morpho Spark",
            bonus: bonusJsx(
                "morpho",
                getFormattedMultplier(apiConfig.contracts.morphoSparkMultiplier)
            ),
            balance: "SOON",
            totalPoints: "SOON",
            canDeposit: false,
            icon: Morpho,
            description: "Deposit eUSDe into Morpho",
            buttonText: "SOON",
        },
        {
            id: "eulerEusde",
            title: "Euler Yield",
            bonus: bonusJsx("euler", getFormattedMultplier(apiConfig.contracts.eulerMultiplier)),
            balance: "SOON",
            totalPoints: "SOON",
            canDeposit: true,
            icon: Euler,
            depositUrl: EULER_LINK_DEPOSIT,
            description: "Deposit eUSDe into Euler",
            buttonText: "Deposit eUSDe",
        },
    ];

    const headers = [
        { name: "Venue", className: "w-1/4" },
        { name: "Balance", className: "w-1/6 text-right" },
        { name: "Points", className: "w-1/6 text-right" },
        { name: "Bonus", className: "w-1/6 text-right" },
        { name: "Action", className: "w-1/4 text-right" },
    ];

    const renderHeader = ({ name, className }: { name: string; className?: string }) => {
        const headerClassName = `${className || ""} text-[10px] font-secondary text-text-secondary`;

        return (
            <div key={name} className={headerClassName}>
                {name}
            </div>
        );
    };

    const renderDepositRow = (props: DepositRowProps, idx: number) => {
        const {
            canDeposit,
            icon,
            title,
            bonus,
            balance,
            totalPoints,
            depositUrl,
            description,
            buttonText,
        } = props;
        const renderAction = () => {
            if (canDeposit && depositUrl) {
                return (
                    <a
                        className={
                            "flex text-primary text-xs gap-1 justify-end cursor-pointer hover:brightness-110"
                        }
                        role="link"
                        target="_blank"
                        href={depositUrl}
                        rel="noopener noreferrer"
                    >
                        {buttonText || "Deposit eUSDe"}{" "}
                        <ArrowUpRightIcon className=" fill-primary size-4" />
                    </a>
                );
            }
            return <div className="text-gray-500 font-medium">{buttonText || "Hold"}</div>;
        };

        let mobileClass = "md:hidden flex flex-col p-2 mb-4";
        // only show bottom border if not last row or if there is a referral row
        if (idx !== earnOptions.length - 1 || referral?.myReferralCode) {
            mobileClass = `${mobileClass} border-b border-border`;
        }
        // Mobile card layout
        const mobileLayout = (
            <div className={mobileClass}>
                {/* Asset Name and Logo */}
                <div className="flex items-center space-x-3 mb-3">
                    <img src={icon} width={32} height={32} alt={title} draggable={false} />
                    <div>
                        <div className="font-medium">{title}</div>
                        <div className="text-text-secondary">
                            {canDeposit ? `Deposit into ${title}` : `Pre-Deposit into ${title}`}
                        </div>
                    </div>
                </div>
                {/* header */}
                <div className="flex px-4 py-1">
                    <div className="text-[10px] text-text-secondary w-1/3">eUSDe</div>
                    <div className="text-[10px] text-text-secondary w-1/3 text-right">Points</div>
                    <div className="text-[10px] text-text-secondary w-1/3 text-right">Bonus</div>
                </div>
                {/* values */}
                <div className="flex p-4">
                    <div className="w-1/3 text-left">{balance}</div>
                    <div className="w-1/3 text-right">{totalPoints}</div>
                    <div className="w-1/3 text-right">{bonus}</div>
                </div>

                {/* Action */}
                <div className="mt-6 flex justify-end">{renderAction()}</div>
            </div>
        );

        let desktopClass = "hidden md:flex items-center justify-between py-4 pl-4 pr-3";
        // only show bottom border if not last row or if there is a referral row
        if (idx !== earnOptions.length - 1 || referral?.myReferralCode) {
            desktopClass = `${desktopClass} border-b border-border`;
        }
        // Desktop table layout
        const desktopLayout = (
            <div className={desktopClass}>
                <div className="flex items-center space-x-3 w-1/4">
                    <img src={icon} width={32} height={32} alt={title} draggable={false} />
                    <div>
                        <div>{title}</div>
                        <div className="text-text-secondary">
                            {description
                                ? description
                                : canDeposit
                                  ? `Deposit into ${title}`
                                  : `Pre-Deposit into ${title}`}
                        </div>
                    </div>
                </div>
                <div
                    className={`w-1/6 text-right ${Number(balance) === 0 || isNaN(Number(balance)) ? "text-text-secondary" : ""}`}
                >
                    {balance}
                </div>
                <div
                    className={`w-1/6 text-right ${Number(totalPoints) === 0 || isNaN(Number(totalPoints)) ? "text-text-secondary" : ""}`}
                >
                    {totalPoints}
                </div>
                <div className="w-1/6 text-right">{bonus}</div>
                <div className="w-1/4 text-right">{renderAction()}</div>
            </div>
        );

        return (
            <>
                {mobileLayout}
                {desktopLayout}
            </>
        );
    };

    return (
        <div>
            {/* Header - Only visible on desktop */}
            <div className="hidden md:flex items-center justify-between py-1 px-4 border-border bg-backgroundSecondary">
                {headers.map((h) => renderHeader(h))}
            </div>

            {/* Rows */}
            <div className="md:bg-transparent text-xs ">
                {earnOptions.map((option, idx) => {
                    return <div key={idx}>{renderDepositRow(option, idx)}</div>;
                })}
            </div>
        </div>
    );
};

export default AssetTable;
