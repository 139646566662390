import { Button } from "@Catalyst/button";
import { Dialog, DialogBody } from "@Catalyst/dialog";
import { Checkbox, CheckboxField } from "@Catalyst/checkbox";
import { Label } from "@Catalyst/fieldset";
import { useEffect, useState } from "react";
import { Divider } from "@Catalyst/divider";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface Props {
    open: boolean;
    handleClose: () => void;
    handleAccept: () => Promise<void>;
}
const ConfirmWithdrawModal: React.FC<Props> = ({ open, handleClose, handleAccept }) => {
    const [accept, setAccept] = useState(false);

    useEffect(() => {
        setAccept(false);
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={() => {}} // prevents closing on click out of dialog
            className={`!bg-backgroundPrimary !p-0 fixed overflow-hidden flex flex-col top-1/2 
                left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] max-w-[90%] md:max-w-[350px] mx-auto !rounded-md`}
        >
            <div className="flex flex-row justify-between items-center p-4 text-base text-text-primary">
                <div>Confirm withdraw</div>
                <button data-testid="close-btn" onClick={handleClose}>
                    <XMarkIcon className="!size-6 fill-text-secondary hover:brightness-150" />
                </button>
            </div>
            <Divider />
            <DialogBody className="p-4 !my-0">
                <CheckboxField className="flex justify-start !items-start">
                    <Checkbox
                        name="accept"
                        onChange={() => setAccept(!accept)}
                        checked={accept}
                        className="mt-1"
                    />
                    <Label className="text-sm">
                        I understand that by withdrawing my pre-deposit early I may impact any
                        loyalty rewards to be recognized in later seasons
                    </Label>
                </CheckboxField>

                <Button
                    data-testid="accept-withdraw-btn"
                    onClick={handleAccept}
                    className="w-full mt-6"
                    color="red"
                    disabled={!accept}
                >
                    <span className="text-base">Withdraw</span>
                </Button>
            </DialogBody>
        </Dialog>
    );
};
export default ConfirmWithdrawModal;
