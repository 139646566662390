import { z } from "zod";
import { Address, isAddress } from "viem";
import { NodeEnvironment } from "./util/interfaces";

// Contract-related configuration
const contractSchema = z.object({
    vault: z.object({
        depositCap: z.coerce.number().default(0),
        address: z
            .custom<Address>(isAddress)
            .default("0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512" as Address),
        assetToken: z.object({
            address: z
                .custom<Address>(isAddress)
                .default("0x5FbDB2315678afecb367f032d93F642f64180aa3" as Address),
            decimals: z.coerce.number().positive().default(18),
        }),
    }),
    pendle: z.object({
        lpAddress: z
            .custom<Address>(isAddress)
            .default("0x85667e484a32d884010Cf16427D90049CCf46e97" as Address),
        ytAddress: z
            .custom<Address>(isAddress)
            .default("0x708dD9B344dDc7842f44C7b90492CF0e1E3eb868" as Address),
        decimals: z.coerce.number().positive().default(18),
    }),
    curve: z.object({
        address: z
            .custom<Address>(isAddress)
            .default("0x0000000000000000000000000000000000000000" as Address),
    }),
});

// API and service configuration
const serviceSchema = z.object({
    apiUrl: z.string().trim().default("http://localhost:3000/v1"),
    mainnetRpcUrl: z.string().trim().url(),
    testnetRpcUrl: z.string().trim().url().default("https://ethereum-sepolia-rpc.publicnode.com"),
    walletConnectProjectId: z.string().trim().default(""),
    network: z.nativeEnum(NodeEnvironment).default(NodeEnvironment.LOCAL),
});

// Combined configuration schema
const configSchema = z.object({
    isProd: z.boolean(),
    contracts: contractSchema,
    services: serviceSchema,
    pollingIntervalMs: z.coerce.number().positive().default(60_000),
    decimalDisplayPrecision: z.coerce.number().positive().default(2),
    priorityUserUrl: z
        .string()
        .trim()
        .url()
        .default("https://app.deform.cc/form/d22c69c7-6eb8-40c0-a346-c9bb7e383884"),
});

// Configuration type
export type Config = z.infer<typeof configSchema>;

// Parse and validate environment variables
function parseConfig(): Config {
    try {
        return configSchema.parse({
            contracts: {
                vault: {
                    depositCap: import.meta.env.VITE_DEPOSIT_CAP,
                    address: import.meta.env.VITE_VAULT_ADDRESS,
                    assetToken: {
                        address: import.meta.env.VITE_VAULT_ASSET_TOKEN_ADDRESS,
                        decimals: import.meta.env.VITE_VAULT_ASSET_TOKEN_DECIMALS,
                    },
                },
                pendle: {
                    ytAddress: import.meta.env.VITE_PENDLE_YT_ADDRESS,
                    lpAddress: import.meta.env.VITE_PENDLE_ADDRESS,
                    decimals: import.meta.env.VITE_PENDLE_DECIMALS,
                },
                curve: { address: import.meta.env.VITE_CURVE_ADDRESS },
            },
            services: {
                apiUrl: import.meta.env.VITE_POINTS_API_URL,
                walletConnectProjectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID,
                network: import.meta.env.VITE_NETWORK,
                mainnetRpcUrl: import.meta.env.VITE_MAINNET_RPC_URL,
                testnetRpcUrl: import.meta.env.VITE_TESTNET_RPC_URL,
            },
            pollingIntervalMs: import.meta.env.VITE_POLLING_INTERVAL_MS,
            decimalDisplayPrecision: import.meta.env.VITE_DECIMAL_DISPLAY_PRECISION,
            isProd: import.meta.env.PROD,
            priorityUserUrl: import.meta.env.VITE_PRIORITY_USER_URL,
        });
    } catch (error) {
        if (error instanceof z.ZodError) {
            console.error(
                "Configuration validation failed:",
                error.errors.map((e) => `${e.path.join(".")}: ${e.message}`).join("\n")
            );
        }
        throw error;
    }
}

// Create and export the validated configuration
export const config = parseConfig();
