import { useEffect, useRef, useState } from "react";

export function useHighlight<T>(value: T, duration = 200) {
    const [isHighlighted, setIsHighlighted] = useState(false);
    const previousValue = useRef<T>(value);

    useEffect(() => {
        if (value === previousValue.current) return;
        setIsHighlighted(true);

        const timeout = setTimeout(() => {
            setIsHighlighted(false);
        }, duration);

        previousValue.current = value;

        return () => clearTimeout(timeout);
    }, [value, duration]);

    return isHighlighted;
}
