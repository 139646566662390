// hooks/useScreenSize.ts
import { useState, useEffect } from "react";

// Tailwind's default breakpoints
export const BREAKPOINTS = {
    xs: 0, // Default/Mobile first
    sm: 640, // @media (min-width: 640px)
    md: 768, // @media (min-width: 768px)
    lg: 1024, // @media (min-width: 1024px)
    xl: 1280, // @media (min-width: 1280px)
    "2xl": 1536, // @media (min-width: 1536px)
} as const;

type Breakpoint = keyof typeof BREAKPOINTS;

interface ScreenSize {
    width: number;
    height: number;
    breakpoint: Breakpoint;
    isXs: boolean;
    isSm: boolean;
    isMd: boolean;
    isLg: boolean;
    isXl: boolean;
    is2xl: boolean;
}

export const useScreenSize = (): ScreenSize => {
    const [screenSize, setScreenSize] = useState<ScreenSize>(() => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        // Determine current breakpoint
        const breakpoint = (Object.entries(BREAKPOINTS)
            .reverse()
            .find(([, value]) => width >= value)?.[0] ?? "xs") as Breakpoint;

        return {
            width,
            height,
            breakpoint,
            isXs: width >= BREAKPOINTS.xs && width < BREAKPOINTS.sm,
            isSm: width >= BREAKPOINTS.sm && width < BREAKPOINTS.md,
            isMd: width >= BREAKPOINTS.md && width < BREAKPOINTS.lg,
            isLg: width >= BREAKPOINTS.lg && width < BREAKPOINTS.xl,
            isXl: width >= BREAKPOINTS.xl && width < BREAKPOINTS["2xl"],
            is2xl: width >= BREAKPOINTS["2xl"],
        };
    });

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;

            // Determine current breakpoint
            const breakpoint = (Object.entries(BREAKPOINTS)
                .reverse()
                .find(([, value]) => width >= value)?.[0] ?? "xs") as Breakpoint;

            setScreenSize({
                width,
                height,
                breakpoint,
                isXs: width >= BREAKPOINTS.xs && width < BREAKPOINTS.sm,
                isSm: width >= BREAKPOINTS.sm && width < BREAKPOINTS.md,
                isMd: width >= BREAKPOINTS.md && width < BREAKPOINTS.lg,
                isLg: width >= BREAKPOINTS.lg && width < BREAKPOINTS.xl,
                isXl: width >= BREAKPOINTS.xl && width < BREAKPOINTS["2xl"],
                is2xl: width >= BREAKPOINTS["2xl"],
            });
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return screenSize;
};
