import { createContext, Dispatch, SetStateAction } from "react";
import {
    AccountDto,
    DynamicPointsDisplayInfo,
    ReferralDto,
    ApiConfigDto,
} from "@src/util/interfaces";

export interface GlobalContextInterface {
    accounts: AccountDto[] | null;
    referral: ReferralDto | null;
    balance: string;
    getBalance: () => Promise<void>;
    eUsdebalance: string;
    geteUsdeBalance: () => Promise<void>;
    withdrawalEnabled: boolean;
    leaderboardRank: string | null;
    selectedChainId: number;
    refetchAccountData: () => Promise<void>;
    ytPendleBalance: string;
    lpPendleBalance: string;
    setReferral: Dispatch<SetStateAction<ReferralDto | null>>;
    dynamicPointsDisplayInfo: DynamicPointsDisplayInfo;
    apiConfig: ApiConfigDto;
}

export const GlobalContext = createContext<GlobalContextInterface>({} as GlobalContextInterface);
