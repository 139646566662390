import React from "react";
import { useGlobalContext } from "@src/hooks/Global/useGlobalContext";
import { formatNumberTwoDecimalsFromString } from "@src/util/util";

const SeasonStats: React.FC = () => {
    const { dynamicPointsDisplayInfo, referral, apiConfig, leaderboardRank } = useGlobalContext();
    const rewardPct = referral?.referralPct || apiConfig.referralPointPct;
    const refereeRewards = dynamicPointsDisplayInfo.totalRefereePts * (rewardPct / 100);

    function seasonRankTsx() {
        return (
            <div>
                <div className="text-text-secondary">Total rank</div>
                <span className="text-4xl font-medium">#{leaderboardRank}</span>
            </div>
        );
    }

    // Returns my total points = my points + referee rewards
    function getMyTotalPointsDisplayStr() {
        const myPts = dynamicPointsDisplayInfo.myPts;
        const totalPts = myPts + refereeRewards;
        return formatNumberTwoDecimalsFromString(totalPts.toString());
    }

    function getTotalRefereeRewardsDisplayStr() {
        return formatNumberTwoDecimalsFromString(refereeRewards.toString());
    }

    return (
        <div
            className={`relative w-full md:h-36 h-44 bg-cover bg-center flex justify-center items-center lg:rounded`}
        >
            <div className="absolute gap-4 flex items-center justify-between mx-8 max-w-6xl w-full px-8  md:flex-row flex-col lg:rounded">
                {/* Left/Top section */}
                <div className="flex justify-center flex-col items-center md:items-start md:justify-start">
                    <div className="text-text-secondary text-xl">Total points</div>
                    <div className={`text-4xl font-medium`}>{getMyTotalPointsDisplayStr()}</div>
                    <div className="text-sm text-text-secondary mt-1">
                        {dynamicPointsDisplayInfo.totalRefereePts > 0 &&
                            `including ${getTotalRefereeRewardsDisplayStr()} from referrals`}
                    </div>
                </div>
                {/* Right/Bottom section */}
                <div className="flex items-center md:space-x-12 space-x-24 text-center">
                    {seasonRankTsx()}
                </div>
            </div>
        </div>
    );
};

export default SeasonStats;
