import Eth from "../assets/tokenIcons/Eth.svg";
import Ethereal from "../assets/tokenIcons/Ethereal.svg";
import Optimism from "../assets/tokenIcons/Optimisim.svg";
import Arbitrum from "../assets/tokenIcons/Arbitrum.svg";
import { Token } from "./interfaces";

export enum SOCIAL_LINKS {
    DISCORD = "https://discord.gg/etherealdex",
    TWITTER = "https://x.com/etherealdex",
    GITHUB = "https://github.com/etherealdex",
    MIRROR = "https://mirror.xyz/0x71331A0991C312fcCF766c3Ca8F7a11c4f5F756B",
}
export const DOCS_LINK = "https://docs.ethereal.trade/";

export const PENDLE_DEPOSIT_LINK_YT =
    "https://app.pendle.finance/trade/markets/0x85667e484a32d884010cf16427d90049ccf46e97/swap?view=yt&chain=ethereum";
export const PENDLE_DEPOSIT_LINK_LP =
    "https://app.pendle.finance/trade/pools/0x85667e484a32d884010cf16427d90049ccf46e97/zap/in?chain=ethereum";

export const MORPHO_APOSTRO_LINK_EUSDE =
    "https://app.morpho.org/ethereum/market/0x140fe48783fe88d2a52b31705577d917628caaf74ff79865b39d4c2aa6c2fd3c/eusde-usde";

export const EULER_LINK_DEPOSIT = "https://app.euler.finance/?asset=eUSDe&network=ethereum";

export const TOAST_MAX_CHARS = 100;

export const ETH_TOKEN: Token = { name: "Ethereum", symbol: "ETH", icon: Eth };

export const ETHEREAL_TOKEN: Token = { name: "Ethereal", symbol: "ETHER", icon: Ethereal };
export const OPTIMISM_TOKEN: Token = { name: "Optimism", symbol: "OP", icon: Optimism };
export const ARBITRUM_TOKEN: Token = { name: "Arbitrum", symbol: "ARB", icon: Arbitrum };
export const MOCK_TOKENS: Token[] = [ETH_TOKEN, ETHEREAL_TOKEN, OPTIMISM_TOKEN, ARBITRUM_TOKEN];

export const MS_PER_DAY = 1000 * 60 * 60 * 24;
