import Accordion from "@Components/GeneralComponents/Accordion";
import React from "react";

const FAQs: React.FC = () => {
    const accordionItems = [
        {
            id: "1",
            title: "What is Ethereal?",
            content: (
                <p>
                    Ethereal is a high performance spot and perpetuals DEX built on the Ethena
                    Network.
                </p>
            ),
        },
        {
            id: "2",
            title: "What is Season Zero?",
            content: (
                <p>
                    Season Zero opens up pre-deposits into Ethereal. Participating users receive
                    Ethereal Points (in addition to Ethena rewards), priority access to new product
                    launches, and loyalty bonuses for early supporters in future programs.
                </p>
            ),
        },
        {
            id: "3",
            title: "How do I participate?",
            content: (
                <p>
                    To participate, simply deposit USDe into the Ethereal deposit contract on
                    Ethereum L1. Ethereal Points are distributed linearly as a function of deposit
                    size and duration.
                </p>
            ),
        },
        {
            id: "4",
            title: "How long is Season Zero expected to last?",
            content: (
                <p>
                    Season Zero is expected to last until Ethereal's mainnet launch later in the
                    year (planned for May 2025).
                </p>
            ),
        },
        {
            id: "5",
            title: "Are my funds locked?",
            content: (
                <p>
                    No. USDe deposited into the Ethereal pre-deposit contract can be withdrawn any
                    time.
                </p>
            ),
        },
        {
            id: "6",
            title: "What is eUSDe?",
            content: (
                <p>
                    eUSDe is the receipt token for USDe pre-deposits into Ethereal that enables
                    composability with other DeFi protocols. When a deposit is made, eUSDe is issued
                    which can be redeemed for USDe at any time.
                </p>
            ),
        },
    ];

    return (
        <div className="mx-auto  w-full">
            <div className="text-xl font-medium mb-6">Frequently asked questions</div>
            <Accordion items={accordionItems} allowMultiple={true} />
        </div>
    );
};

export default FAQs;
